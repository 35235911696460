import axiosClient from "../../apiClient";
import {refreshTimerBase} from '@/store/SessionTimer'
import {useAuthStore} from "@/stores/auth";

export const ApiMixin = {
    methods: {
        doGet(api, params) {
            refreshTimerBase();
            window.REQUEST_TIMESTAMP = new Date().getTime();
            return axiosClient.get(api, { params })
                .then(response => {
                    // TODO interceptor to handle error messages

                    const requiresLogin = response.data.requiresLogin !== undefined ? response.data.requiresLogin : false;
                    console.log("get apiUtil " + api + ": " + requiresLogin);
                    const authStore = useAuthStore();
                    if (requiresLogin && authStore.isLoggedIn) {
                        authStore.setAuthenticated(false);
                        return;
                    }

                    refreshTimerBase();

                    return response.data;
                })
                .catch(function (error) {
                    if (this != undefined)
                    {
                        this.makeToast('danger', error);
                    }
                });
        },
        doPost(api, params) {
            refreshTimerBase();
            window.REQUEST_TIMESTAMP = new Date().getTime();
            return axiosClient.post(api, params)
                .then(response => {
                    // TODO interceptor to handle error messages

                    const requiresLogin = response.data.requiresLogin !== undefined ? response.data.requiresLogin : false;
                    console.log("post apiUtil " + api + ": " + requiresLogin);
                    const authStore = useAuthStore();
                    if (requiresLogin && authStore.isLoggedIn) {
                        authStore.setAuthenticated(false);
                        return;
                    }

                    refreshTimerBase();

                    return response.data;
                })
                .catch(function (error) {
                    if (this != undefined)
                    {
                        this.makeToast('danger', error);
                    }
                });
        }
    }
}