<template>
  <div id="mealPlans">
    <vue-loading v-if="isLoading" />

    <div id="mealPlan" v-if="selectedMealPlan" class="d-flex gap10 flex-column">
      <div v-for="group in selectedMealPlan.groups" :key="group.description" class="meal-container">
        <div class="meal-group">
          <h1 class="meal-group-title">
            <span class="text-left">{{ group.description }}</span>
            <span class="text-right">
              <strong>{{ productCountByGroup(group) }}</strong> / <strong>{{ group.maxProducts }}</strong>
              <span v-if="group.minProducts > 0" style="color: red;"> ({{ $t('text.minProducts') }}: {{ group.minProducts }})</span>
            </span>
          </h1>
        </div>
        <div class="grid-layout">
          <div
              v-for="product in group.items"
              :key="product.kkProdId"
              :class="{ 'disabled-item': !isMealPlanValid || product.locked }"
          >
            <div :id="product.kkProdId" class="meal-item">
              <img v-if="product.image1" :src="product.image1" :alt="product.name" class="meal-image" />
              <div class="d-flex flex-column gap10 flex-grow-1 justify-content-between">
                <div class="meal-details" @click="isMealPlanValid && !product.locked && !isGroupMaxReached(group) && viewProductDetails(product)">
                  <div class="meal-description">
                    <span>{{ product.name }}</span>
                    <div class="properties"></div>
                    <div class="allergens">
                      <div v-for="property in product.properties" :key="property.id">
                        <div>
                          <img v-if="property.image" :src="property.image" :alt="property.name" class="property-img" />
                        </div>
                      </div>
                      <div v-for="allergen in product.allergens" :key="allergen.id">
                        <div>
                          <img v-if="allergen.image" :src="allergen.image" :alt="allergen.name" class="allergen-img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row gap10 w-100 flex-wrap">
                  <div
                      class="button"
                      :class="{ disabled: !isMealPlanValid }"
                      @click="isMealPlanValid && !isGroupMaxReached(group) && viewProductDetails(product)"
                      role="button"
                      tabindex="0"
                      aria-label="View product details"
                  >
                    <font-awesome-icon :icon="['fas', 'circle-info']" />
                  </div>
                  <FlexSpinButton
                      :class="{ disabled: !isMealPlanValid || product.locked }"
                      :min="0"
                      :max="maxProductsForGroups.find(
                            g => g.group.description === group.description
                          ).maxAvailableForProducts.find(
                            p => p.product.kkProdId === product.kkProdId
                          ).maxAvailable"
                      :modelValue="productCountByGroup(group, product)"
                      @update:modelValue="(newCount) => handleProductUpdate(product, group, newCount)"
                      role="button"
                      tabindex="0"
                      aria-label="Add or remove product from basket"
                  />
                  <span class="meal-price" style="white-space: nowrap">{{ product.formattedPrice }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal for product details -->
      <b-modal id="product-details-modal" hide-footer :title="selectedProduct ? selectedProduct.name : ''">
        <ProductDetails v-if="selectedProduct" :productId="selectedProduct.kkProdId" @add-to-basket="addToBasket" />
      </b-modal>

    </div>
  </div>
</template>

<script>
import ProductDetails from "@/components/sub-components/ProductDetails.vue";
import { basketService } from "@/services/api/basketService";
import VueLoading from "vue-loading-overlay";
import FlexSpinButton from "@/components/page-components/templates/FlexSpinButton.vue";
import {useProductsStore} from "@/stores/products";

export default {
  name: "MealPlan",
  components: {
    FlexSpinButton,
    VueLoading,
    ProductDetails,
  },
  props: {
    selectedMealPlan: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      isLoading: false,
      selectedProduct: null,
      selectedProductsCount: {}, // Track selected products count by group
    };
  },
  computed: {
    isMealPlanValid() {
      if (!this.selectedMealPlan) {
        return false;
      }

      const currentTime = new Date();
      const mealPlanStartDate = this.getMealPlanStartDate(this.selectedMealPlan);
      return currentTime < mealPlanStartDate;
    },
    maxProductsForGroups() {
      // Calculate max products for each group and each product within that group
      return this.selectedMealPlan.groups.map(group => {
        const currentCount = this.productCountByGroup(group);  // Total count of products in the group
        const maxAvailable = group.maxProducts - currentCount; // Remaining slots available

        // For each product in the group, calculate maxAvailable at the product level
        return {
          group,
          maxAvailableForProducts: group.items.map(product => {
            const productCount = this.productCountByGroup(group, product);  // Get current count of the product
            return {
              product,
              maxAvailable: maxAvailable + productCount,  // Add the current product's count back in
            };
          })
        };
      });
    }
  },
  setup() {
    const productStore = useProductsStore();

    return {
      productStore
    };
  },
  methods: {
    handleProductUpdate(product, group, newCount) {
      // Check if the product has children
      if (product.hasChildren) {
        // If the product has children, open product details
        this.viewProductDetails(product);
      } else {
        // If no children, update the product count
        this.updateProductCount(product, group, newCount);
      }
    },
    updateProductCount(product, group, newCount) {
      const currentCount = this.productCountByGroup(group, product);

      if (!this.selectedProductsCount[group.description]) {
        this.$set(this.selectedProductsCount, group.description, {});
      }
      if (!this.selectedProductsCount[group.description][product.kkProdId]) {
        this.$set(this.selectedProductsCount[group.description], product.kkProdId, 0);
      }

      // If new count is higher, add more
      if (newCount > currentCount) {
        const difference = newCount - currentCount;
        this.addToBasket(group, product, difference);
      }
      // If new count is lower, remove the excess
      else if (newCount < currentCount) {
        const difference = currentCount - newCount;
        this.removeFromBasket(group, product, difference);
      }
    },

    productCountByGroup(group, product = null) {
      if (product) {
        return this.selectedProductsCount[group.description]?.[product.kkProdId] || 0;
      } else {
        return Object.values(this.selectedProductsCount[group.description] || {}).reduce((sum, count) => sum + count, 0);
      }
    },

    isGroupMaxReached(group) {
      return this.productCountByGroup(group) >= group.maxProducts;
    },
    addToBasket(group, product, quantity = 1) {
      // Ensure the group exists
      if (!this.selectedProductsCount[group.description]) {
        this.$set(this.selectedProductsCount, group.description, {});
      }

      this.addProduct(product, quantity); // Add to the actual basket
      this.$set(this.selectedProductsCount[group.description], product.kkProdId, this.productCountByGroup(group, product) + quantity);
    },

    removeFromBasket(group, product, quantity = 1) {
      // Ensure the group exists
      if (!this.selectedProductsCount[group.description]) {
        this.$set(this.selectedProductsCount, group.description, {});
      }

      this.removeProduct(product, quantity); // Remove from the actual basket
      const currentCount = this.productCountByGroup(group, product);
      this.$set(this.selectedProductsCount[group.description], product.kkProdId, currentCount - quantity);
    },

    async addProduct(product, quantity = 1) {
      this.isLoading = true;
      const result = await basketService.addProductToBasket(product.kkProdId, 'dinein', false, quantity, this.selectedMealPlan.id);
      this.isLoading = false;

      if (result.exception !== undefined) {
        this.makeToast("danger", this.$t("notification.addItemFailed"));
        return;
      }

      // Increment basket count
      this.productStore.incrementProductCount(quantity); // Adjust the basket count by the added quantity
      this.makeToast("success", this.$t("notification.addItemSuccess"));
    },
    async removeProduct(product, quantity = 1) {
      this.isLoading = true;
      const result = await basketService.removeProductFromBasket(product.kkProdId, 'dinein', quantity); // Pass quantity to remove
      this.isLoading = false;

      if (result.exception !== undefined) {
        this.makeToast("danger", this.$t("notification.removeItemFailed"));
        return;
      }

      // Decrement basket count
      this.productStore.decrementProductCount(quantity); // Adjust the basket count by the removed quantity
    },
    getMealPlanStartDate(mealPlan) {
      const validDate = new Date(mealPlan.dateValid);
      const [startHour, startMinute] = mealPlan.formattedStart.split(':');
      validDate.setHours(startHour, startMinute, 0, 0);
      return validDate;
    },

    viewProductDetails(product) {
      this.selectedProduct = product;
      this.$bvModal.show("product-details-modal");
    },
  },
};
</script>

<style scoped>
/* Style updates for disabled state */
.button.disabled {
  background-color: #d3d3d3; /* Light gray to indicate disabled */
  pointer-events: none;
}

.disabled-item {
  opacity: 0.5;
  pointer-events: none;
}

.meal-container {
  background-color: var(--page-container-background);
  border-radius: var(--button-border-radius);
  margin-top: 10px
}

.meal-group {
  background-color: var(--page-group-header);
  margin: 0 auto;
  padding: 0 10px;
  border-radius: var(--button-border-radius);
}

.meal-group-title {
  font-size: 16px;
  color: var(--page-group-title);
  display: flex;
  justify-content: space-between; /* Space between the two spans */
  align-items: center; /* Center the text vertically */
  width: 100%; /* Ensure it takes the full width */
}

.text-left {
  flex: 1; /* Allow the left text to grow */
  text-align: left; /* Align left text */
}

.text-right {
  text-align: right; /* Align right text */
}

.meal-item {
  display: flex;
  flex: 1 0 360px;
  gap: 10px;
  text-align: left;
  align-items: stretch;
  padding: 10px;
  border-radius: var(--button-border-radius);
  background-color: var(--page-group-item);
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition effect */
  height: 100%;
}

.meal-item:hover {
  background-color: #e0e0e0;
  transform: scale(1.02);
  cursor: pointer;
}

.meal-image {
  width: 120px;
  height: auto;
  border-radius: var(--button-border-radius);
  object-fit: cover;
}

.meal-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.meal-description {
  font-weight: bold;
  text-align: left;
}

.properties {
  filter: hue-rotate(45deg);
}

.allergens, .properties {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  margin-top: 5px;
}

.allergen-img,
.property-img {
  max-width: 30px;
}

.meal-price {
  color: indianred;
  font-size: 14px;
  align-self: center;
  flex-grow: 1;
  text-align: right;
}

.button {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s;
}

.button:hover,
.button:focus {
  background-color: #0056b3;
}

.button:active {
  background-color: #004494;
}
</style>