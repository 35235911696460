import Vue from 'vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

Vue.use(PiniaVuePlugin); // Integrate Pinia with Vue

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate); // Apply persistence plugin

export default pinia;
