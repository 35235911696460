import { apiUtils } from "@/utils/apiUtils";

export const paymentService = {
    async initPayment() {
        try {
            const result = await apiUtils.doGet('/SaferpayJ.action', {
                json: "true"
            });

            return result;
        } catch (error) {
            console.error("Error on init payment:", error);
            throw error;
        }
    },
};
