import { defineStore } from 'pinia';

export const useProductsStore = defineStore('products', {
    // State
    state: () => ({
        productCount: 0,  // Overall product count across meal plans
        products: [],     // List of products with quantities
    }),

    // Getters
    getters: {
        getProductCount: (state) => state.productCount,  // Get the total product count
        getProducts: (state) => state.products,          // Get all products with quantities

        // Get product by ID
        getProductById: (state) => (productId) => {
            return state.products.find(product => product.id === productId);
        },
    },

    // Actions
    actions: {
        incrementProductCount({ productId, quantity = 1 }) {
            const existingProduct = this.products.find(product => product.id === productId);
            if (existingProduct) {
                existingProduct.quantity += quantity;
            } else {
                this.products.push({ id: productId, quantity });
            }
            this.productCount += quantity;
        },

        decrementProductCount({ productId, quantity = 1 }) {
            const existingProduct = this.products.find(product => product.id === productId);
            if (existingProduct && existingProduct.quantity > 0) {
                const decrementQuantity = Math.min(existingProduct.quantity, quantity);
                existingProduct.quantity -= decrementQuantity;
                this.productCount -= decrementQuantity;

                if (existingProduct.quantity <= 0) {
                    this.products = this.products.filter(product => product.id !== productId);
                }
            }
        },

        setProductCount({ productId, quantity }) {
            const existingProduct = this.products.find(product => product.id === productId);
            if (existingProduct) {
                this.productCount += quantity - existingProduct.quantity;
                existingProduct.quantity = quantity;
            } else {
                this.products.push({ id: productId, quantity });
                this.productCount += quantity;
            }
        },

        addProduct(product) {
            const existingProduct = this.products.find(p => p.id === product.id);
            if (!existingProduct) {
                this.products.push(product);
                this.productCount += product.quantity;
            }
        },

        removeProduct(productId) {
            const product = this.products.find(p => p.id === productId);
            if (product) {
                this.productCount -= product.quantity;
                this.products = this.products.filter(p => p.id !== productId);
            }
        },
    },
});