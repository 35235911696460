import { apiUtils } from "@/utils/apiUtils";
import {useAuthStore} from "@/stores/auth";
import {refreshTimerBase, removeTimerBase} from "@/store/SessionTimer";

export const accountService = {
    async beforeLogin() {
        try {
            const result = await apiUtils.doGet('TCPLoginJ.action', {
                json: "true"
            });

            return result;
        } catch (error) {
            console.error("Error on before login:", error);
            throw error;
        }
    },
    async login(emailAddr, password, responseToken, rememberMe) {
        try {
            const result = await apiUtils.doPost('/TCPLoginSubmitJ.action', {
                json: "true",
                loginUsername: emailAddr,
                password: password,
                responseToken: responseToken != "" ? responseToken : undefined,
                rememberMe: rememberMe
            });

            const authStore = useAuthStore();
            if (authStore.isLoggedIn) {
                authStore.setAuthenticated(false);
                return;
            }

            refreshTimerBase();

            return result;
        } catch (error) {
            console.error("Error on customer login:", error);
            throw error;
        }
    },
    async afterLogin() {
        try {
            const result = await apiUtils.doGet('/TCPAfterLoginJ.action', {
                json: "true",
            });

            return result;
        } catch (error) {
            console.error("Error on after login:", error);
            throw error;
        }
    },
    async logout() {
        try {
            const result = await apiUtils.doGet('/TCPLogoutJ.action', {});
            removeTimerBase();
            return result;
        } catch (error) {
            console.error("Error on logout", error);
            throw error;
        }
    },
    async selectChild(id) {
        try {
            const result = await apiUtils.doGet('/TCPSelectChildJ.action', {
                json: "true",
                wondChildId: id
            });

            return result;
        } catch (error) {
            console.error("Error select child:", error);
            throw error;
        }
    }
};
