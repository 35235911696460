import { defineStore } from 'pinia';
import { shopService } from '@/services/api/shopService';

export const useShopsStore = defineStore('shops', {
    // State
    state: () => ({
        shops: [],        // List of shops
        selectedShop: null, // Selected shop
    }),

    // Getters
    getters: {
        getShops: (state) => state.shops,
        selectedShop: (state) => state.selectedShop,
    },

    // Actions
    actions: {
        // Fetch the list of shops
        async fetchShops() {
            try {
                const shops = await shopService.getShops(false);
                this.shops = shops;
            } catch (error) {
                console.error("Failed to fetch shops:", error);
            }
        },

        // Select a shop
        selectShop(shop) {
            this.selectedShop = shop;
        },
    },
});