import Vue from 'vue';
import VueSimpleAlert from "vue-simple-alert";
import App from './App.vue';
import VueCookies from 'vue-cookies'
import VueValidate from 'vuelidate'
import VueLoading from 'vue-loading-overlay'
import VueAxios from "vue-axios";
import axios from 'axios';
import NavigationPlugin from './plugins/navigation';
import {BootstrapVue, BootstrapVueIcons, IconsPlugin} from "bootstrap-vue";
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faAddressBook,
    faCartPlus,
    faCartShopping,
    faCircleArrowLeft,
    faCircleMinus,
    faCirclePlus,
    faCircleInfo,
    faTicket,
    faTrashCan,
    faPlus,
    faCreditCard,
    faClockRotateLeft,
    faArrowLeft,
    faPowerOff,
    faShop,
    faShopSlash,
    faLock,
    faUtensils,
    faClock,
    faBan,
    faCashRegister,
    faCircleLeft,
    faBurger,
    faCalendarDays,
    faReceipt,
    faRectangleList,
    faBars,
    faUser,
    faTimes,
    faChevronRight,
    faArrowRightLong,
    faArrowLeftLong,
    faUserGraduate,
    faQrcode,
    faPenToSquare,
    faEnvelope,
    faEye, faKey, faHandDots, faFloppyDisk, faCheckCircle, faTimesCircle, faExclamationTriangle, faHome
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {ApiMixin} from "@/components/mixins/apiMixin";
import {PaymentMixin} from "@/components/mixins/paymentMixin";
import {AlertMixin} from "@/components/mixins/alertMixin";
import {loadCss} from "@/utils/loadCss";
import i18n from "@/i18n";
import router from "@/router/index.js";
import {faFacebookF, faInstagram, faTwitter} from "@fortawesome/free-brands-svg-icons";
import pinia from "@/stores";

Vue.use(VueAxios, axios);
Vue.use(VueSimpleAlert);
Vue.use(VueCookies);
Vue.use(VueValidate);
Vue.use(VueLoading);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(IconsPlugin);
Vue.use(NavigationPlugin);

const vueApp = new Vue({
    i18n,
    router,
    pinia,
    render: h => h(App),
});

Vue.mixin(ApiMixin);
Vue.mixin(AlertMixin);
Vue.mixin(PaymentMixin);


Vue.config.productionTip = false

// Loading a CSS file at runtime
loadCss(process.env.BASE_URL + '/settings/resources/custom.css').then(() => {
  console.log('CSS file loaded successfully');
}).catch((error) => {
  console.error(error);
});

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(
    faAddressBook,
    faCartPlus,
    faCartShopping,
    faCircleArrowLeft,
    faCircleMinus,
    faCirclePlus,
    faCircleInfo,
    faTicket,
    faTrashCan,
    faPlus,
    faCreditCard,
    faClockRotateLeft,
    faArrowLeft,
    faPowerOff,
    faShop,
    faShopSlash,
    faLock,
    faUtensils,
    faClock,
    faBan,
    faCashRegister,
    faCircleLeft,
    faBurger,
    faCalendarDays,
    faReceipt,
    faRectangleList,
    faBars,
    faUser,
    faUserGraduate,
    faTimes,
    faChevronRight,
    faArrowRightLong,
    faArrowLeftLong,
    faQrcode,
    faPenToSquare,
    faFacebookF,
    faTwitter,
    faInstagram,
    faEnvelope,
    faEye,
    faKey,
    faHandDots, faFloppyDisk, faCheckCircle, faTimesCircle, faExclamationTriangle, faHome
)

// Set locale on startup
// const savedLocale = store.getters['locale/getLocale']; // Fetch persisted locale window.config.LANGUAGE_LIST[0].value;
// store.dispatch('locale/setLocale', savedLocale); // Set it at startup

// Global error handler
vueApp.errorHandler = (err, vueInstance, info) => {

  // Handle the error globally
  console.error("Global error:", err);
  console.log("Vue instance:", vueInstance);
  console.log("Error info:", info);

  // UI notification
  vueInstance.makeToast('warning', vueInstance.$t('notification.genericError'));
};

vueApp.$mount('#app');

// eslint-disable-next-line no-unused-vars
Vue.mixin({
  data: function() {
    return {
      globalCartObject: {
        Type:Array
      },
      TableMode : false
    }
  },
  computed: {
    shoppingCart() {
      return this.globalCartObject;
    }
  },
  methods: {
    updateShoppingCart: async function () {
      let vueInstance = this;

      const result = await vueInstance.doGet('TCPConfirmOrderJ.action', {
        calculateOrder: window.customerLoggedIn ? true : false,
        orderType: window.customerLoggedIn ? window.orderType : undefined,
      });

      window.shoppingCart = result.basketTile.basketItems;
      window.shoppingTile = result.basketTile;
    }
  }
});

