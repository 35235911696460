import Vue from 'vue';
import Router from 'vue-router';
import AccountPage from "@/components/page-components/AccountPage.vue";
import LoginPage from "@/components/page-components/LoginPage.vue";
import ForgotPasswordPage from "@/components/page-components/ForgotPasswordPage.vue";
import ShopPage from "@/components/page-components/ShopPage.vue";
import MenuPage from "@/components/page-components/MenuPage.vue";
import ShoppingCart from "@/components/page-components/ShoppingCartPage.vue";
import RegisterPage from "@/components/page-components/RegisterPage.vue";
import RegisterAsParentPage from "@/components/page-components/RegisterAsParentPage.vue";
import RegisterAsChildPage from "@/components/page-components/RegisterAsChildPage.vue";
import GuestCheckout from "@/components/page-components/GuestCheckoutPage.vue";
import QRLandingPage from "@/components/page-components/QRLandingPage.vue";
import ErrorSSOLandingPage from "@/components/page-components/ErrorSSOLandingPage.vue";
import PasswordResetPage from "@/components/page-components/PasswordResetPage.vue";
import AddressSelectionPage from "@/components/page-components/AddressSelectionPage.vue";
import EditCustomerPage from "@/components/page-components/EditCustomerPage.vue";
import TopUp from "@/components/page-components/TopUp.vue";
import OrderHistoryPage from "@/components/page-components/OrderHistoryPage.vue";
import TransactionHistoryPage from "@/components/page-components/TransactionHistoryPage.vue";
import StudentAccounts from "@/components/page-components/StudentAccounts.vue";
import AccountConfirm from "@/components/page-components/AccountConfirm.vue";
import TablePayPage from "@/components/page-components/TablePayPage.vue";
import OrderConfirmationPage from "@/components/page-components/OrderConfirmationPage.vue";
import CheckoutFinishedPage from "@/components/page-components/CheckoutFinishedPage.vue";
import PaymentEndPage from "@/components/page-components/PaymentEndPage.vue";
import PaymentPage from "@/components/page-components/PaymentPage.vue";
import LoginSSOPage from "@/components/page-components/LoginSSOPage.vue";
import ShopOverview from "@/components/page-components/ShopOverview.vue";
import MealPlanner from "@/components/page-components/MealPlanner/MealPlanner.vue";
import CustomerCard from "@/components/page-components/CustomerCard.vue";
import Dashboard from "@/components/page-components/Dashboard/Dashboard.vue";
import Template from "@/components/page-components/templates/Template.vue";
import {useAuthStore} from "@/stores/auth";
import pinia from "@/stores";

Vue.use(Router);

let config = window.config;

const router = new Router({
    mode: 'history',
    base: '/dist/',
    routes: [
        {
            path: '/',
            redirect: config.DEFAULT_START_PAGE.enabled ? config.DEFAULT_START_PAGE.path : '/login',
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard,
            meta: { title: 'title.dashboard' }
        },
        {
            path: '/template',
            name: 'Template',
            component: Template,
            meta: { enabled: process.env.NODE_ENV !== 'production', title: 'Template' }
        },
        {
            path: '/login',
            name: 'Login',
            component: LoginPage,
            meta: { title: 'title.login', ariaTitle: 'ariaLabel.login' }
        },
        {
            path: '/loginSSO',
            name: 'LoginSSO',
            component: LoginSSOPage,
            props: route => ({ jwt: route.query.id_token, validationType: route.query.validationType, immediate: route.query.i, shopId: route.query.s })
        },
        {
            path: '/register',
            name: 'Register',
            component: RegisterPage,
            meta: { title: 'title.register', showBackButton: true }
        },
        {
            path: '/registerAsParent',
            name: 'RegisterAsParent',
            component: RegisterAsParentPage,
            meta: { enabled: config.STUDENT_ACCOUNTS.enabled, title: 'title.registerAsParent', showBackButton: true }
        },
        {
            path: '/guest',
            name: 'Guest',
            component: GuestCheckout,
            meta: { enabled: config.GUEST_CHECKOUT.enabled, title: 'title.registerGuest', showBackButton: false }
        },
        {
            path: '/forgotPassword',
            name: 'ForgotPassword',
            component: ForgotPasswordPage,
            meta: { title: 'title.passwordForgot', showBackButton: true }
        },
        {
            path: '/shop',
            name: 'Shop',
            component: ShopPage,
            meta: { enabled: config.ORDER.enabled, title: 'title.shop', showBackButton: false },
            props: route => ({ code: route.query.code, jwt: route.query.id_token, shopId: route.query.s, validationType: route.query.validationType, isMealPlan: route.query.isMealPlan })
        },
        {
            path: '/menu',
            name: 'Menu',
            component: MenuPage,
            meta: { enabled: config.ORDER.enabled, title: 'title.menu', showBackButton: true, requiresLogin: window.config.FORCE_LOGIN_BEFORE_USE }
        },
        // TODO check if cart can be removed
        {
            path: '/cart',
            name: 'Cart',
            component: ShoppingCart,
        },
        {
            path: '/tablePay',
            name: 'TablePay',
            component: TablePayPage
        },
        {
            path: '/account',
            name: 'Account',
            component: AccountPage,
            meta: { title: 'title.account', showBackButton: false, requiresLogin: true }
        },
        {
            path: '/account/passwordReset',
            name: 'PasswordReset',
            component: PasswordResetPage,
            meta: { title: 'title.passwordReset', showBackButton: true, requiresLogin: true }
        },
        {
            path: '/account/address',
            name: 'Address',
            component: AddressSelectionPage,
            // TODO enabled: config.ADDRESS.enabled
            meta: { title: 'title.address', showBackButton: true, requiresLogin: true }
        },
        {
            path: '/account/editCustomer',
            name: 'EditCustomer',
            component: EditCustomerPage,
            meta: { enabled: config.CUSTOMER_DATA.editable, title: 'title.editCustomer', showBackButton: true, requiresLogin: true }
        },
        {
            path: '/customerCard',
            name: 'CustomerCard',
            component: CustomerCard,
            meta: { enabled: config.CUSTOMER_CARD.enabled, title: 'title.customerCard', requiresLogin: true },
        },
        {
            path: '/topup',
            name: 'TopUp',
            component: TopUp,
            meta: { enabled: config.TOPUP.enabled, title: 'title.charge', showBackButton: true, requiresLogin: true },
        },
        {
            path: '/topup/:customerId',
            name: 'TopUp',
            component: TopUp,
            meta: { enabled: config.TOPUP.enabled, title: 'title.charge', showBackButton: true, requiresLogin: true },
            props: true
        },
        {
            path: '/orderHistory',
            name: 'OrderHistory',
            component: OrderHistoryPage,
            meta: { enabled: config.ORDER_HISTORY.enabled, title: 'title.orderHistory', requiresLogin: true }
        },
        {
            path: '/transactionHistory',
            name: 'TransactionHistory',
            component: TransactionHistoryPage,
            meta: { enabled: config.TRANSACTION_HISTORY.enabled, title: 'title.transactionHistory', showBackButton: false, requiresLogin: true },
        },
        {
            path: '/transactionHistory/:customerId',
            name: 'TransactionHistory',
            component: TransactionHistoryPage,
            meta: { enabled: config.TRANSACTION_HISTORY.enabled, title: 'title.transactionHistory', showBackButton: false, requiresLogin: true },
            props: true
        },
        {
            path: '/shopOverview',
            name: 'ShopOverview',
            component: ShopOverview,
            meta: { enabled: config.MEAL_PLAN.enabled, title: 'title.shop', showBackButton: false },
            props: route => ({ isMealPlan: route.query.isMealPlan })
        },
        {
            path: '/mealPlanner',
            name: 'MealPlanner',
            component: MealPlanner,
            meta: { enabled: config.MEAL_PLAN.enabled, title: 'title.mealPlan', showBackButton: false },
            props: route => ({ isMealPlan: route.query.isMealPlan })
        },
        {
            path: '/studentAccounts',
            name: 'StudentAccounts',
            component: StudentAccounts,
            meta: { enabled: config.STUDENT_ACCOUNTS.enabled, title: 'title.studentAccounts', requiresLogin: true },
        },
        {
            path: '/registerAsChild',
            name: 'RegisterAsChild',
            component: RegisterAsChildPage,
            meta: { enabled: config.STUDENT_ACCOUNTS.enabled, title: 'title.registerAsChild', showBackButton: true, requiresLogin: true }
        },
        {
            path: '/orderConfirmation',
            name: 'OrderConfirmation',
            component: OrderConfirmationPage,
            meta: { title: 'title.orderConfirmation', showBackButton: true, requiresLogin: true }
        },
        {
            path: '/payment',
            name: 'Payment',
            component: PaymentPage,
            meta: { title: 'title.payment', showBackButton: true, requiresLogin: true }
        },

        // Pages to redirect and replace browser history
        {
            path: '/confirm',
            name: 'Confirm',
            component: AccountConfirm,
            props: route => ({ k: route.query.key, m: route.query.m, i: route.query.i})
        },
        {
            path: '/qrLanding',
            name: 'QRLanding',
            component: QRLandingPage,
            props: route => ({ table: route.query.t, shop: route.query.s, home: route.query.h, code: route.query.code, jwt: route.query.id_token, immediate: route.query.i })
        },
        {
            path: '/errorSSO',
            name: 'ErrorSSO',
            component: ErrorSSOLandingPage
        },
        {
            path: '/checkoutFinished',
            name: 'CheckoutFinished',
            component: CheckoutFinishedPage,
            meta: { requiresLogin: true },
        },
        {
            path: '/paymentEnd',
            name: 'PaymentEnd',
            component: PaymentEndPage,
            meta: { requiresLogin: true },
            props: route => ({ payment: route.query.p, result: route.query.r })
        },
    ],
});

router.beforeEach((to, from, next) => {
    console.log(`Navigating from ${from.fullPath} to ${to.fullPath}`);

    const authStore = useAuthStore(pinia); // Access the store using Pinia instance

    // Skip guard if navigating to the same route
    if (to.fullPath === from.fullPath) {
        console.log("Skipping guard for identical paths");
        return next();
    }

    // Authentication check
    if (!authStore.isLoggedIn && to.meta.requiresLogin) {
        console.log("User not authenticated, redirecting to Login");
        return next({ name: 'Login' });
    }

    // Prevent logged-in users from accessing Login
    if (authStore.isLoggedIn && to.name === 'Login') {
        console.log("User already logged in, redirecting to default page or dashboard");
        return next({ path: config.DEFAULT_START_PAGE.enabled ? config.DEFAULT_START_PAGE.path : '/dashboard' });
    }

    // Feature enabled check
    const isEnabled = to.meta.enabled ?? true;
    if (!isEnabled) {
        console.log("Feature not enabled, redirecting to default page or dashboard");
        return next({ path: config.DEFAULT_START_PAGE.enabled ? config.DEFAULT_START_PAGE.path : '/dashboard' });
    }

    // Proceed to the requested route
    console.log("Navigation allowed");
    next();
});

export default router;
