<template>
  <div class="customer-card" role="region">
    <!-- Loading Indicator -->
    <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="true"
        loader="bars"
        aria-live="assertive"
        aria-busy="true"
        role="status">
    </loading>

    <div class="customerCardGrid rowGap20">
      <FlexContainer id="container-customer-card-digital" :title="$t('subTitle.customerCardDigital')" #content v-if="config.SHOW_CUSTOMER_QR_CODE">
        <div class="qr-section">
          <radial-progress-bar
              style="margin:auto"
              :diameter="config.QRCODE_RADIAL_PROGRESSBAR_SIZE"
              :completed-steps="30 - qrCodeSeconds"
              :total-steps="30"
              startColor='var(--main-color)'
              stopColor='var(--main-color)'
              innerStrokeColor="lightgray"
              role="progressbar"
              :aria-valuemin="0"
              :aria-valuemax="30"
              :aria-valuenow="qrCodeSeconds"
              :aria-valuetext="qrCodeSeconds"
              :aria-label="$t('text.QRCodeRemaining',[qrCodeSeconds])">
            <qr-code
                :value="qrCode"
                :size="config.QRCODE_SIZE"
                :aria-label="$t('text.customerCardAsQRCode')"
                role="img">
            </qr-code>
            {{ qrCodeSeconds }}s
          </radial-progress-bar>
        </div>
      </FlexContainer>

      <FlexContainer id="container-2" :title="$t('subTitle.customerCardInfos')" #content>
        <h3 class="normalText"><strong>{{ $t('text.cardHolder') }}:</strong> {{ customer.firstName }} {{ customer.lastName }}</h3>
        <h3 class="normalText"><strong>{{ $t('text.cardNumber') }}:</strong> {{ wondCustomer.cardNum }}</h3>
        <h3 class="normalText"><strong>{{ $t('text.customerCardBalance') }}</strong> {{ wondCustomer.formattedPrepayBalanceCash }}</h3>

        <button v-if="config.TOPUP.enabled" class="btn btn-primary btn-lg btnSubmit mx-0 w-100 noMargin" @click="goToTopUp()" :aria-label="$t('button.charge')"
                aria-live="polite">
          <span class="button-text">{{ $t('button.charge') }}</span>
        </button>
      </FlexContainer>
    </div>
  </div>
</template>

<script>
import Utils from "@/utils/utils";
import moment from "moment/moment";
import CryptoJS from "crypto-js";
import base32Encode from "base32-encode";
import {UserTypes} from "@/constants/constants";
import VueLoading from "vue-loading-overlay";
import QrcodeVue from "qrcode.vue";
import RadialProgressBar from "vue-radial-progress";
import FlexContainer from "@/components/page-components/templates/FlexContainer.vue";
import {accountService} from "@/services/api/accountService";

export default {
  name: 'customer-card',
  components:{
    FlexContainer,
    'loading' : VueLoading,
    'qr-code' : QrcodeVue,
    'radial-progress-bar': RadialProgressBar,
  },
  data() {
    return {
      isLoading: false,
      pendingCustomerCardPayments:{
        Type: Array,
        default: []
      },
      cardNum: "",
      qrCode:"",
      qrCodeSeconds: 30,
      intervalId: 0,
      prepayBalanceCash: 0,
      customerCardWithCreditFunktion: false,
      creditBalance: 0,
      currencyCode: undefined,
      isParent: false,
      isChild: false,
      wondCustomer: {},
      customer:{}
    };
  },
  computed: {
    Utils() {
      return Utils
    },
    config() {
      return window.config;
    },
  },
  methods: {
    goToTopUp() {
      this.$navigateToTopUp();
    },
    wordToByteArray(wordArray) {
      var byteArray = [], word, i, j;
      for (i = 0; i < wordArray.length; ++i) {
        word = wordArray[i];
        for (j = 3; j >= 0; --j) {
          byteArray.push((word >> 8 * j) & 0xFF);
        }
      }
      return byteArray;
    },
    startQrCodeInterval() {
      this.generateQrCode();
      this.intervalId = setInterval(() => {
        this.qrCodeSeconds = this.qrCodeSeconds - 1;

        if(this.qrCodeSeconds == 0)
        {
          this.generateQrCode();
        }
      }, 1000);
    },
    generateQrCode() {
      var config = window.config;

      // 1- Gets cardNumber
      let date = new Date();
      var newDateObj = moment(date).add(30,'seconds').utc().format('yyyyMMDDHHmmss');
      var barcode = this.cardNum + newDateObj;

      //checksum
      var result = '*';
      var code39Chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ-. $/+%";
      var sum = 0;
      for (var i = 0; i < barcode.length; i++)
      {
        sum += code39Chars.indexOf(barcode.charAt(i));
      }
      result = code39Chars[sum % 43];
      barcode = barcode + result;

      var C = CryptoJS;
      var bytes = C.enc.Utf8.parse(barcode);

      var key = new Uint8Array(32);
      var IV = new Uint8Array(16);
      key = new Uint8Array( [60, 175, 77, 70, 58, 137, 151,252,5,30,164,241,182,3,136,151,129,196,108,49,255,108,131,159,207,90,24,11,38,215,110,138] );
      IV = new Uint8Array( [140,184,62,82,119,188,41,89,188,112,122,223,51,65,170,21] );
      var aes = C.algo.AES.createEncryptor(C.lib.WordArray.create(key), {
        mode: C.mode.CBC,
        padding: C.pad.Pkcs7,
        iv: C.lib.WordArray.create(IV)
      });

      var encrypted = aes.finalize(bytes);
      var uint8Array = this.wordToByteArray(encrypted.words);
      var uint9Array = new Uint8Array(uint8Array);
      var qrcode = base32Encode(uint9Array, 'RFC4648', { padding: false });
      qrcode = "TCPOSAPP" + qrcode;

      if (config.USE_SHORT_QRCODE == true) {
        const response = this.doGet('TCPGetShortQRCodeJ.action',{ json: "true", code: qrcode })
        this.qrCode = response.exception !== undefined || response.QRCodeProperties === undefined ? "ERROR" : response.QRCodeProperties.shortCode;
      } else {
        this.qrCode = qrcode
      }

      this.qrCodeSeconds=30;
      this.$forceUpdate();
    },
  },
  async created() {
    const config = this.config;

    this.isLoading = true;
    const result = await accountService.afterLogin();
    this.isLoading = false;

    if (result.exception !== undefined || result.wondCustomer === undefined) {
      this.makeToast('danger', this.$t('notification.customerCard'));
      return;
    }

    this.isParent = UserTypes.PARENT === result.customer.custom3 ? true : false;
    this.isChild = UserTypes.CHILD === result.customer.custom3 ? true : false;

    window.isGuestCheckout = result.customer != undefined && result.customer.type != undefined && result.customer.type == 2 ? true : false;

    this.customer = result.customer;
    this.currencyCode = result.basketTile.currencyCode;

    if (result.wondCustomer != undefined) {
      this.wondCustomer = result.wondCustomer;
      this.cardNum = result.wondCustomer.cardNum;
      if (result.wondCustomer.cardType.toUpperCase() === "CREDIT") {
        let separator = Utils.getDecimalSeparator(result.wondCustomer.formattedCreditBalance);
        this.creditBalance = result.wondCustomer.creditBalance.toFixed(config.CUSTOMER_BALANCE_DECIMAL_PLACES).replace('.', separator);
        this.customerCardWithCreditFunktion = true;
      } else {
        let separator = Utils.getDecimalSeparator(result.wondCustomer.formattedPrepayBalanceCash);
        this.prepayBalanceCash = (result.wondCustomer.prepayBalanceCash + result.wondCustomer.prepayBalanceVoucher + result.wondCustomer.prepayBalanceBonus).toFixed(config.CUSTOMER_BALANCE_DECIMAL_PLACES).replace('.', separator);
      }
      this.startQrCodeInterval();

      if (result.wondCustomer.pendingTopup != undefined) {
        this.pendingCustomerCardPayments = result.wondCustomer.pendingTopup;
      }
    }
  },
  mounted(){
    Utils.adjustInnerScrollHeight();
    window.addEventListener('resize', Utils.adjustInnerScrollHeight);
  },
};
</script>

<style>
/* Each Column Takes 50% Width */
.column {
  flex: 1;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.customerCardGrid {
  display:flex;
  flex-wrap: wrap;
  & > * {
    flex: 1 0 320px;
  }
}

/* Header Title Style */
.header-title {
  text-align: left;
  margin-bottom: 20px;
}

.header-title h2 {
  font-size: 24px;
  color: var(--main-color);
}

/* QR Code Section Styling */
.qr-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Customer Info List Style */
.column-right ul {
  list-style-type: none;
  padding: 0;
}

.column-right li {
  margin-bottom: 10px;
}

/* Button Style */
.column-right button {
  margin-top: 20px;
}

/* Responsive Design for Small Screens */
@media (max-width: 768px) {
  .flex-container {
    flex-direction: column;
  }

  .column {
    max-width: 100%;
  }
}
</style>