<template>
  <div class="toggle-sidebar-container">

    <!-- Toggle Button -->
    <b-button :aria-label="$t('ariaLabel.menu')" v-b-toggle.sidebar @click="toggleSidebar" class="toggle-sidebar-btn">
      <font-awesome-icon v-if="!isSidebarOpen" :icon="['fas', 'bars']" style="transform:translate(-.5px,-2px);"/>
      <font-awesome-icon v-else :icon="['fas', 'times']" style="transform:translate(0, -2px);" />
    </b-button>

    <!-- Sidebar -->
    <b-sidebar id="sidebar"
               bg-variant="light"
               shadow
               backdrop
               no-header-close
               :aria-label="$t('ariaLabel.sidebar')"
               @shown="onSidebarShown"
               @hidden="onSidebarHidden">

      <div class="d-flex flex-column h-100">

        <div class="sidebar-header text-center p-3">
          <img :src="logoPath" alt="Logo" class="img-fluid mb-4" style="max-width: 100px;">
        </div>

        <!-- Navigation Links -->
        <b-list-group flush class="text-left flex-grow-1" v-if="isLoggedin()">
          <b-list-group-item v-for="item in getLinks()" :key="item.key" class="p-3" button @click="item.action()">
            <span class="icon-wrapper">
              <font-awesome-icon :icon="['fas', item.icon]" />
            </span>
            <!--{{ // item.name[0] === '$' ? $t(item.name.substring(1)):item.name }}-->
            {{ item.name() }}
          </b-list-group-item>
        </b-list-group>

        <div class="sidebar-footer mt-auto d-flex">
            <b-button id="btnLanguages" class="btn btn-inverted-header flex-fill button-with-border" v-for="entry in config.LANGUAGE_LIST" :key="entry.value" v-on:click="changeLanguage(entry.value)" :aria-label="$t('ariaLabel.changeLanguageNative', entry.value)" v-bind:aria-pressed="isLanguageActive(entry.value) ? 'true' : 'false'" :lang="entry.title">{{entry.title}}</b-button>
        </div>

      </div>

    </b-sidebar>
  </div>
</template>

<script>
  import {accountService} from "@/services/api/accountService";
  import {useLocaleStore} from "@/stores/locale";
  import {useProductsStore} from "@/stores/products";
  import {useAuthStore} from "@/stores/auth";

  export default {
    data() {
      return {
        isSidebarOpen: false,
        items:[
          //   todo: load from $router
          {key: 1,name: ()=>this.$t('title.dashboard'), action: this.navigateToDashboard, icon: 'home'},
          {key: 2,name: ()=>this.$t('title.customerCard'), action: this.navigateToCustomerCard, icon: 'qrcode'},
          {key: 3,name: ()=>this.$t('title.account'), action: this.navigateToAccount, icon: 'user'},
          {key: 4,name: ()=>this.$t('title.order'), action: this.navigateToShop, icon: 'burger', visible: ()=>this.config.ORDER.enabled},
          {key: 5,name: ()=>this.$t('title.mealPlan'), action: this.navigateToShopOverview.bind(this, true), icon: 'calendar-days', visible: ()=>this.config.MEAL_PLAN.enabled},
          {key: 6,name: ()=>this.$t('title.orderHistory'), action: this.navigateToOrderHistory, icon: 'rectangle-list', visible: ()=>this.config.ORDER_HISTORY.enabled},
          {key: 7,name: ()=>this.$t('title.transactionHistory'), action: this.navigateToTransactionHistory, icon: 'receipt', visible: ()=>this.config.TRANSACTION_HISTORY.enabled},
          {key: 8,name: ()=>this.$t('title.studentAccounts'), action: this.navigateToStudentAccounts, icon: 'user-graduate', visible: ()=>this.config.STUDENT_ACCOUNTS.enabled},
          {key: 9,name: ()=>this.$t('button.logout'), action: this.logout, icon: 'power-off'},
        ],
      };
    },
    computed: {
      logoPath() {
        return process.env.BASE_URL + 'settings/resources/logo.png';
      },
      config() {
        return window.config;
      },
    },
    setup() {
      const authStore = useAuthStore();
      const localeStore = useLocaleStore();
      const productStore = useProductsStore();

      return {
        authStore,
        localeStore,
        productStore
      };
    },
    methods: {
      async changeLanguage(newLocale) {
        await this.localeStore.setLocale(newLocale);
      },
      resetCount() {
        this.productStore.setProductCount(0);
      },
      toggleSidebar() {
        this.isSidebarOpen = !this.isSidebarOpen;
      },
      onSidebarShown() {
        this.isSidebarOpen = true;
      },
      onSidebarHidden() {
        this.isSidebarOpen = false;
      },
      navigateToAccount() {
        this.$navigateToAccount();
      },
      navigateToShop() {
        this.resetCount();
        this.$navigateToShop();
      },
      navigateToShopOverview(isMealPlan) {
        this.$navigateToShopOverview(isMealPlan);
      },
      navigateToCustomerCard() {
        this.$navigateToCustomerCard();
      },
      navigateToTransactionHistory() {
        this.$navigateToTransactionHistory();
      },
      navigateToOrderHistory() {
        this.$navigateToOrderHistory();
      },
      navigateToStudentAccounts() {
        this.$navigateToStudentAccounts();
      },
      navigateToDashboard() {
        this.$navigateToDashboard();
      },
      isLanguageActive(language)
      {
        return this.$i18n.locale === language;
      },
      isLoggedin() {
        return this.authStore.isLoggedIn;
      },
      getLinks() {
        return this.$data.items.filter(function(item){return item.visible === undefined || item.visible();});
      },
      async logout() {
        const result = await accountService.logout();
        if (result.errorCode < 0) {
          this.makeToast('danger', result.errorMsg);
          return;
        }

        this.authStore.setAuthenticated(false);
        this.menuVisible = false; // Close the menu first
        await this.$nextTick(); // Wait for the DOM to update
        this.$navigateToLogin(); // Then navigate to the login page
      }
    },
  };
</script>

<style scoped>
  .toggle-sidebar-container {
    position: unset;
    display: flex;
    align-items: center;
  }

  .toggle-sidebar-btn {
    background: none;
    border: 2px solid #ffffff;
    cursor: pointer;
    font-size: 1.5rem;
    outline: none;
    height: 50px; /* size between open and closed are different; it jumps */
    width: 50px;
  }

  ::v-deep .b-sidebar {
    top: Max(10vh,55px);
    position: absolute;
    max-height: unset;
    height: calc(100vh - Max(10vh,55px));
  }

  ::v-deep .b-sidebar-outer {
    top: 0;
    position: absolute;
  }

  ::v-deep .b-sidebar-backdrop {
    position: absolute;
    bottom: 0;
    right: 0;
    width: unset;
  }

  .sidebar nav ul {
    list-style: none;
    padding: 20px;
    margin: 0;
  }

  .sidebar nav ul li {
    margin-bottom: 15px;
  }

  .sidebar nav ul li a {
    text-decoration: none;
    color: #333;
  }

  .sidebar nav ul li a:hover {
    color: #007bff;
  }

  .toggle-sidebar-btn i {
    transition: transform 0.3s ease;
  }

  .toggle-sidebar-btn i.fas.fa-times {
    color: #ff0000;
  }

  .sidebar-footer {
    background-color: grey;
    text-align: center;
    gap: 1px;
    padding-top: 1px;
  }

  .sidebar-footer .btn {
    border: 0;
    color: #495057;
    &:focus-visible,
    &:hover,
    &:focus { /* layout issue with overlapping elements */
      z-index: 1;
      background-color: var(--main-color);
      color: var(--btn-text-color);
    }
  }

  .button-with-border {
    border-radius: 0;
  }

  .icon-wrapper {
    display: inline-block;
    width: 30px; /* Setze die Breite auf einen Wert, der für alle Icons passt */
    text-align: center;
  }
</style>
