<template>
  <div style="margin-bottom: 10px;" role="group">
    <h2 class="sr-only normalText">{{ $t('text.filter') }}</h2>
    <b-container fluid class="d-flex flex-column noPadding">
      <b-button block v-b-toggle.accordion-1 variant="primary" :aria-label="isOpen ? $t('text.closeFilter') : $t('text.openFilter') " style="margin: 0 0 10px" v-model="isOpen" v-on:click="setToggleState"><b-icon :icon="isOpen ? 'chevron-down' : 'chevron-up'"></b-icon></b-button>
      <b-collapse id="accordion-1" visible accordion="my-accordion">
        <b-card :aria-label="$t('text.filter')">
          <b-form class="dataSearchForm">
            <b-form-row>
              <b-col md="4">
                <b-form-group label-for="dateFrom" :label="$t('field.dateFrom')">
                 <!-- <b-form-datepicker class="form-control form-control-lg" id="dateFrom" v-model="dateFrom" :locale="baseLocale" :date-format-options="dateFormatOptions" /> -->
                    <b-form-input class="form-control form-select-lg"
                        id="dateFrom"
                        v-model="dateFrom"
                        type="date">
                    </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label-for="dateTo" :label="$t('field.dateTo')">
                  <!--<b-form-datepicker class="form-control form-control-lg" id="dateTo" v-model="dateTo" :locale="baseLocale" :date-format-options="dateFormatOptions" />-->
                  <b-form-input class="form-control form-select-lg"
                                id="dateTo"
                                v-model="dateTo"
                                type="date">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label-for="period" :label="$t('field.period')">
                  <b-form-select class="form-control form-select-lg" id="period" v-model="period" @change="setPeriodDates">
                    <b-form-select-option value="actualMonth">{{ $t('filter.actualMonth') }}</b-form-select-option>
                    <b-form-select-option value="previousMonth">{{ $t('filter.previousMonth') }}</b-form-select-option>
                    <b-form-select-option v-if="useFutureFilters" value="nextMonth">{{ $t('filter.nextMonth') }}</b-form-select-option>
                    <b-form-select-option value="actualYear">{{ $t('filter.actualYear') }}</b-form-select-option>
                    <b-form-select-option value="previousYear">{{ $t('filter.previousYear') }}</b-form-select-option>
                    <b-form-select-option v-if="useFutureFilters" value="nextYear">{{ $t('filter.nextYear') }}</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-form>
          <b-button class="btn btn-primary btn-lg btn-block noMargin" id="searchBtn" type="submit" @click.stop="search">{{ $t('button.search') }}</b-button>
        </b-card>
      </b-collapse>
    </b-container>
  </div>
</template>

<script>
import Utils from "@/utils/utils";

export default {
  name: 'date-search-table',
  data() {
    return {
      isOpen: true,
      dateFrom: Utils.getStartOfMonth(),
      dateTo: Utils.getEndOfMonth(),
      period: 'actualMonth',
      fullLocale: this.$i18n.locale,
      dateFormatOptions: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      },
    };
  },
  props: {
    callback: {
      Type: Function
    },
    useFutureFilters: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    '$i18n.locale': function(newVal, oldVal) {
      if(newVal != oldVal)
      {
        this.fullLocale = this.$i18n.locale;
      }
    }
  },
  computed: {
    baseLocale() {
      return this.fullLocale.split('_')[0]; // Extract base locale
    }
  },
  methods: {
    setToggleState() {
      this.isOpen = !this.isOpen;
    },
    setPeriodDates() {
      if (this.period === 'previousMonth')
      {
        this.dateFrom = Utils.getStartOfPreviousMonth();
        this.dateTo = Utils.getEndOfPreviousMonth();
      }
      else if (this.period === 'actualMonth') {
        this.dateFrom = Utils.getStartOfMonth();
        this.dateTo = Utils.getEndOfMonth();
      }
      else if (this.period === 'nextMonth') {
        this.dateFrom = Utils.getStartOfNextMonth();
        this.dateTo = Utils.getEndOfNextMonth();
      }
      else if (this.period === 'previousYear') {
        this.dateFrom = Utils.getStartOfPreviousYear();
        this.dateTo = Utils.getEndOfPreviousYear();
      }
      else if (this.period === 'actualYear') {
        this.dateFrom = Utils.getStartOfYear();
        this.dateTo = Utils.getEndOfYear();
      }
      else if (this.period === 'nextYear') {
        this.dateFrom = Utils.getStartOfNextYear();
        this.dateTo = Utils.getEndOfNextYear();
      }
    },
    search() {
      this.callback(this.dateFrom, this.dateTo);
    },
  },
};
</script>

<style scoped>

.form-select-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.dataSearchForm {
  padding: 0;
}

input[type="date"].form-control
{
  border-color: #ced4da;
  box-shadow: unset;
  text-align: center;
}
</style>
