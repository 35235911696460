
export default {
    install(Vue) {
        // Define navigation methods
        Vue.prototype.$navigateToDashboard = function(withReplace = false) {
            if (withReplace) {
                this.$router.replace({ name: 'Dashboard' });
            } else {
                this.$router.push({ name: 'Dashboard' });
            }
        };

        Vue.prototype.$navigateToRegister = function() {
            this.$router.push({ name: 'Register' });
        };

        Vue.prototype.$navigateToRegisterAsParent = function() {
            this.$router.push({ name: 'RegisterAsParent' });
        };

        Vue.prototype.$navigateToGuest = function() {
            this.$router.push({ name: 'Guest' });
        };

        Vue.prototype.$navigateToForgotPassword = function() {
            this.$router.push({ name: 'ForgotPassword' });
        };

        // TODO refactor, use only one shop page for both order cases, meal plan and normal order
        Vue.prototype.$navigateToShop = function(code, jwt, shopId, validationType) {
            this.$router.push({ name: 'Shop', query: { code, id_token: jwt, s: shopId, validationType }})
        };

        Vue.prototype.$navigateToShopOverview = function(isMealPlan) {
            this.$router.push({ name: 'ShopOverview', query: { isMealPlan: isMealPlan }})
        };

        Vue.prototype.$navigateToMenu = function() {
            this.$router.push({ name: 'Menu' });
        };

        Vue.prototype.$navigateToArticle = function(article) {
            this.$router.push({ name: 'Article', query: { a: article }});
        };

        Vue.prototype.$navigateToCart = function() {
            this.$router.push({ name: 'Cart' });
        };

        Vue.prototype.$navigateToTablePay = function() {
            this.$router.push({ name: 'TablePay' });
        };

        Vue.prototype.$navigateToTemplate = function() {
            this.$router.push({ name: 'Template' });
        };

        // Pages with required login
        Vue.prototype.$navigateToAccount = function(withReplace = false) {
            if (withReplace) {
                this.$router.replace({ name: 'Account' }).catch(error=> { console.info(error.message) });
            } else {
                this.$router.push({ name: 'Account' }).catch(error=> { console.info(error.message) });
            }
        };

        Vue.prototype.$navigateToCustomerCard = function(withReplace = false) {
            if (withReplace) {
                this.$router.replace({ name: 'CustomerCard' });
            } else {
                this.$router.push({ name: 'CustomerCard' });
            }
        };

        Vue.prototype.$navigateToPasswordReset = function() {
            this.$router.push({ name: 'PasswordReset' });
        };

        Vue.prototype.$navigateToAddress = function() {
            this.$router.push({ name: 'Address' });
        };

        Vue.prototype.$navigateToEditCustomer = function() {
            this.$router.push({ name: 'EditCustomer' });
        };

        Vue.prototype.$navigateToTopUp = function(isChild = false, customerId = 0) {
            if (isChild && customerId > 0) {
                this.$router.push({ name: 'TopUp', params: { customerId: customerId }, query: { isChild: isChild } });
            } else {
                this.$router.push({ name: 'TopUp' });
            }
        };

        Vue.prototype.$navigateToOrderHistory = function() {
            this.$router.push({ name: 'OrderHistory' });
        };

        Vue.prototype.$navigateToTransactionHistory = function(isChild = false, customerId = 0) {
            if (isChild && customerId > 0) {
                this.$router.push({ name: 'TransactionHistory', params: { customerId: customerId }, query: { isChild: isChild } });
            } else {
                this.$router.push({ name: 'TransactionHistory' });
            }
        };

        Vue.prototype.$navigateToMealPlanner = function(withReplace = false, shop) {
            if (withReplace) {
                this.$router.replace({ name: 'MealPlanner', params: { shop } });
            } else {
                this.$router.push({ name: 'MealPlanner', params: { shop } });
            }
        };

        Vue.prototype.$navigateToStudentAccounts = function(withReplace = false) {
            if (withReplace) {
                this.$router.replace({ name: 'StudentAccounts' });
            } else {
                this.$router.push({ name: 'StudentAccounts' });
            }
        };

        Vue.prototype.$navigateToRegisterAsChild = function() {
            this.$router.push({ name: 'RegisterAsChild' });
        };

        Vue.prototype.$navigateToOrderConfirmation = function() {
            this.$router.push({ name: 'OrderConfirmation' });
        };

        Vue.prototype.$navigateToPayment = function() {
            this.$router.push({ name: 'Payment' });
        };

        // Redirect to a page and replace browser history entry to prevent on navigating back
        Vue.prototype.$navigateToConfirm = function() {
            this.$router.replace({ name: 'Confirm' });
        };

        Vue.prototype.$navigateToQRLanding = function() {
            this.$router.replace({ name: 'QRLanding' });
        };

        Vue.prototype.$navigateToErrorSSO = function() {
            this.$router.replace({ name: 'ErrorSSO' });
        };

        Vue.prototype.$navigateToCheckoutFinished = function() {
            this.$router.replace({ name: 'CheckoutFinished' });
        };

        Vue.prototype.$navigateToPaymentEnd = function(payment, result) {
            this.$router.replace({ name: 'PaymentEnd', query: { p: payment, r: result }});
        };

        Vue.prototype.$navigateToLogin = function() {
            this.$router.replace({ name: 'Login' });
        };

        Vue.prototype.$navigateToLoginSSO = function(jwt, validationType) {
            this.$router.replace({ name: 'LoginSSO', query: { jwt, validationType }})
        };
    },
};
