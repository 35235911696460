import axiosClient from "@/apiClient";
import {refreshTimerBase} from '@/store/SessionTimer'
import {useAuthStore} from "@/stores/auth";

export const apiUtils = {
    doGet(api, params) {
        window.REQUEST_TIMESTAMP = new Date().getTime();
        return axiosClient.get(api, { params })
            .then(response => {
                const requiresLogin = response.data.requiresLogin !== undefined ? response.data.requiresLogin : false;
                console.log("get apiUtil " + api + ": " + requiresLogin);
                const authStore = useAuthStore();
                if (requiresLogin && authStore.isLoggedIn) {
                    authStore.setAuthenticated(false);
                    return;
                }

                refreshTimerBase();

                return response.data;
            })
            .catch(function (error) {
                console.error("Error fetching data:", error);
                throw error;
            });
    },
    doPost(api, params) {
        refreshTimerBase();
        window.REQUEST_TIMESTAMP = new Date().getTime();
        return axiosClient.post(api, params)
            .then(response => {
                const requiresLogin = response.data.requiresLogin !== undefined ? response.data.requiresLogin : false;
                console.log("post apiUtil " + api + ": " + requiresLogin);
                const authStore = useAuthStore();
                if (requiresLogin && authStore.isLoggedIn) {
                    authStore.setAuthenticated(false);
                    return;
                }

                refreshTimerBase();

                return response.data;
            })
            .catch(function (error) {
                console.error("Error posting data:", error);
                throw error;
            });
    }
}
