<template>
    <div>
      <b-form @submit.stop.prevent class="noPadding">
        <b-form-row>
          <b-col cols="12" md="6" v-show="config.CUSTOMER_DATA.FIELDS.firstName.visible">
            <b-form-group class="form-group fontField" label-for="firstName">
              <template v-slot:label>
                {{ $t('field.firstName') }} <span v-if="config.CUSTOMER_DATA.FIELDS.firstName.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg"
                            id="firstName" name="firstName"
                            type="text"
                            v-model="$v.form.firstName.$model"
                            :state="validateState('firstName')"
                            aria-describedby="firstName-feedback"
                            :maxLength="config.CUSTOMER_DATA.FIELDS.firstName.maxLength"
                            :required="config.CUSTOMER_DATA.FIELDS.firstName.required" />
              <b-form-invalid-feedback class="text-left" id="firstName-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" v-show="config.CUSTOMER_DATA.FIELDS.lastName.visible">
            <b-form-group class="form-group fontField" label-for="lastName">
              <template v-slot:label>
                {{ $t('field.lastName') }} <span v-if="config.CUSTOMER_DATA.FIELDS.lastName.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg"
                            id="lastName"
                            name="lastName"
                            type="text"
                            v-model="$v.form.lastName.$model"
                            :state="validateState('lastName')"
                            aria-describedby="lastName-feedback"
                            :maxLength="config.CUSTOMER_DATA.FIELDS.lastName.maxLength"
                            :required="config.CUSTOMER_DATA.FIELDS.lastName.required" />
              <b-form-invalid-feedback class="text-left" id="lastName-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-show="config.CUSTOMER_DATA.FIELDS.emailAddr.visible">
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="emailAddr">
              <template v-slot:label>
                {{ $t('field.email') }} <span v-if="config.CUSTOMER_DATA.FIELDS.emailAddr.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg" id="emailAddr" name="emailAddr" type="email" :required="config.CUSTOMER_DATA.FIELDS.emailAddr.required" v-model="$v.form.emailAddr.$model"
                            :state="validateState('emailAddr')" :aria-describedby="validateState('emailAddr')?'':'emailAddr-feedback'" :maxLength="config.CUSTOMER_DATA.FIELDS.emailAddr.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="emailAddr-feedback">{{ $t('validation.invalidEmail') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" md="6" v-show="config.CUSTOMER_DATA.FIELDS.cardNumber.visible">
            <b-form-group class="form-group fontField" label-for="cardNumber">
              <template v-slot:label>
                {{ $t('field.cardNumber') }} <span v-if="config.CUSTOMER_DATA.FIELDS.cardNumber.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg" id="cardNumber" name="cardNumber" type="text" v-model="$v.form.cardNumber.$model"
                            :state="validateState('cardNumber')" aria-describedby="cardNumber-feedback" :maxLength="config.CUSTOMER_DATA.FIELDS.cardNumber.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="cardNumber-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" v-show="config.CUSTOMER_DATA.FIELDS.customerCode.visible">
            <b-form-group class="form-group fontField" label-for="customerCode">
              <template v-slot:label>
                {{ $t('field.customerCode') }} <span v-if="config.CUSTOMER_DATA.FIELDS.customerCode.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg" id="customerCode" name="customerCode" type="text" v-model="$v.form.customerCode.$model"
                            :state="validateState('customerCode')" aria-describedby="customerCode-feedback" :maxLength="config.CUSTOMER_DATA.FIELDS.customerCode.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="customerCode-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" md="6" v-show="config.CUSTOMER_DATA.FIELDS.password.visible">
            <b-form-group class="form-group fontField" label-for="password">
              <template v-slot:label>
                {{ $t('field.password') }} <span v-if="config.CUSTOMER_DATA.FIELDS.password.required" class="text-danger">*</span>
              </template>
              <b-input-group>
                <b-form-input v-if="hidePassword" class="form-control form-control-lg" id="password" name="password" type="password" v-model="$v.form.password.$model"
                              :state="validateState('password')" :required="config.CUSTOMER_DATA.FIELDS.password.required"
                              aria-describedby="password-feedback" />
                <b-form-input v-else class="form-control form-control-lg" id="password" name="password" type="text" v-model="$v.form.password.$model"
                              :state="validateState('password')" :required="config.CUSTOMER_DATA.FIELDS.password.required"
                              aria-describedby="password-feedback" />
                <b-form-invalid-feedback class="text-left order-last" v-if="form.password && !$v.form.password.valid" id="password-feedback">{{ $t('validation.passwordPolicy') }}</b-form-invalid-feedback>
                <b-input-group-append>
                  <b-button v-if="config.SHOW_PASSWORD_VISIBILITY_SWITCH" variant="outline-secondary" type="button" @click="showPassword()" :aria-label="hidePassword ? $t('ariaLabel.showPassword') : $t('ariaLabel.hidePassword')">
                    <b-icon-eye-fill v-if="hidePassword" aria-hidden="true" :aria-label="$t('ariaLabel.showPasswordNew')"></b-icon-eye-fill>
                    <b-icon-eye-slash-fill v-else aria-hidden="true" :aria-label="$t('ariaLabel.hidePasswordNew')"></b-icon-eye-slash-fill>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" v-show="config.CUSTOMER_DATA.FIELDS.passwordRepeat.visible">
            <b-form-group class="form-group fontField" label-for="passwordRepeat">
              <template v-slot:label>
                {{ $t('field.passwordRepeat') }} <span v-if="config.CUSTOMER_DATA.FIELDS.passwordRepeat.required" class="text-danger">*</span>
              </template>
              <b-input-group>
                <b-form-input v-if="hidePasswordRepeat" class="form-control form-control-lg" id="passwordRepeat" name="passwordRepeat" type="password" v-model="$v.form.passwordRepeat.$model"
                              :state="validateState('passwordRepeat')"
                              aria-describedby="passwordRepeat-feedback"
                              :minLength="config.CUSTOMER_DATA.FIELDS.passwordRepeat.minLength"
                              :maxLength="config.CUSTOMER_DATA.FIELDS.passwordRepeat.maxLength"
                              :required="config.CUSTOMER_DATA.FIELDS.passwordRepeat.required"/>
                <b-form-input v-else class="form-control form-control-lg" id="passwordRepeat" name="passwordRepeat" type="text" v-model="$v.form.passwordRepeat.$model"
                              :state="validateState('passwordRepeat')"
                              aria-describedby="passwordRepeat-feedback"
                              :minLength="config.CUSTOMER_DATA.FIELDS.passwordRepeat.minLength"
                              :maxLength="config.CUSTOMER_DATA.FIELDS.passwordRepeat.maxLength"
                              :required="config.CUSTOMER_DATA.FIELDS.passwordRepeat.required"/>
                <b-form-invalid-feedback class="text-left order-last" id="passwordRepeat-feedback">{{ $t('validation.fieldWarningPasswordEqual') }}</b-form-invalid-feedback>
                <b-input-group-append>
                  <b-button v-if="config.SHOW_PASSWORD_VISIBILITY_SWITCH" variant="outline-secondary" type="button" @click="showPasswordRepeat()" :aria-label="hidePasswordRepeat ? $t('ariaLabel.showPasswordRepeat') : $t('ariaLabel.hidePasswordRepeat')">
                    <b-icon-eye-fill v-if="hidePasswordRepeat" aria-hidden="true" :aria-label="$t('ariaLabel.showPasswordRepeat')"></b-icon-eye-fill>
                    <b-icon-eye-slash-fill v-else aria-hidden="true" :aria-label="$t('ariaLabel.hidePasswordRepeat')"></b-icon-eye-slash-fill>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-show="config.CUSTOMER_DATA.FIELDS.streetAddress.visible">
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="streetAddress">
              <template v-slot:label>
                {{ $t('field.street') }} <span v-if="config.CUSTOMER_DATA.FIELDS.streetAddress.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg"
                            id="streetAddress"
                            name="streetAddress" type="text"
                            v-model="$v.form.streetAddress.$model"
                            :state="validateState('streetAddress')"
                            aria-describedby="streetAddress-feedback"
                            :maxLength="config.CUSTOMER_DATA.FIELDS.streetAddress.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="streetAddress-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" md="6" v-show="config.CUSTOMER_DATA.FIELDS.postcode.visible">
            <b-form-group class="form-group fontField" label-for="postcode">
              <template v-slot:label>
                {{ $t('field.postalCode') }} <span v-if="config.CUSTOMER_DATA.FIELDS.postcode.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg"
                            id="postcode"
                            name="postcode"
                            type="text"
                            v-model="$v.form.postcode.$model"
                            :state="validateState('postcode')"
                            aria-describedby="postcode-feedback"
                            :maxLength="config.CUSTOMER_DATA.FIELDS.postcode.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="postcode-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" v-show="config.CUSTOMER_DATA.FIELDS.city.visible">
            <b-form-group class="form-group fontField" label-for="city">
              <template v-slot:label>
                {{ $t('field.city') }} <span v-if="config.CUSTOMER_DATA.FIELDS.city.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg"
                            id="city"
                            name="city"
                            type="text"
                            v-model="$v.form.city.$model"
                            :state="validateState('city')"
                            aria-describedby="city-feedback"
                            :maxLength="config.CUSTOMER_DATA.FIELDS.city.maxLength" />
              <b-form-invalid-feedback class="text-left" id="city-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-show="config.CUSTOMER_DATA.FIELDS.countryId.visible">
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="countryId">
              <template v-slot:label>
                {{ $t('field.country') }} <span v-if="config.CUSTOMER_DATA.FIELDS.countryId.required" class="text-danger">*</span>
              </template>
              <select-picker-country
                  id="customerCountry"
                  name="customerCountry"
                  class="countrySelect"
                  v-if="countriesLoaded"
                  :options="countries"
                  :callback="selectCountry"
                  :preselectedId="selectedCountryId"
                  aria-describedby="countryId-feedback"
                  :state="validateState('countryId')" />
              <b-form-invalid-feedback class="text-left" id="countryId-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-show="config.CUSTOMER_DATA.FIELDS.telephoneNumber.visible">
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="telephoneNumber">
              <template v-slot:label>
                {{ $t('field.telephoneNumber') }} <span v-if="config.CUSTOMER_DATA.FIELDS.telephoneNumber.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg" id="telephoneNumber" name="telephoneNumber" type="text" v-model="$v.form.telephoneNumber.$model"
                            :state="validateState('telephoneNumber')" aria-describedby="telephoneNumber-feedback"/>
              <b-form-invalid-feedback class="text-left" id="telephoneNumber-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" md="6" v-show="config.CUSTOMER_DATA.FIELDS.notes1.visible">
            <b-form-group class="form-group fontField" label-for="notes1">
              <template v-slot:label>
                {{ $t('field.notes1') }} <span v-if="config.CUSTOMER_DATA.FIELDS.notes1.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg" id="notes1" name="notes1" type="text" v-model="$v.form.notes1.$model"
                            :state="validateState('notes1')" aria-describedby="notes1-feedback" :maxLength="config.CUSTOMER_DATA.FIELDS.notes1.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="notes1-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" v-show="config.CUSTOMER_DATA.FIELDS.notes2.visible">
            <b-form-group class="form-group fontField" label-for="notes2">
              <template v-slot:label>
                {{ $t('field.notes2') }} <span v-if="config.CUSTOMER_DATA.FIELDS.notes2.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg" id="notes2" name="notes2" type="text" v-model="$v.form.notes2.$model"
                            :state="validateState('notes2')" aria-describedby="notes2-feedback" :maxLength="config.CUSTOMER_DATA.FIELDS.notes2.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="notes2-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" md="6" v-show="config.CUSTOMER_DATA.FIELDS.notes3.visible">
            <b-form-group class="form-group fontField" label-for="notes3">
              <template v-slot:label>
                {{ $t('field.notes3') }} <span v-if="config.CUSTOMER_DATA.FIELDS.notes3.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg" id="notes3" name="notes3" type="text" v-model="$v.form.notes3.$model"
                            :state="validateState('notes3')" aria-describedby="notes3-feedback" :maxLength="config.CUSTOMER_DATA.FIELDS.notes3.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="notes3-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" v-show="config.CUSTOMER_DATA.FIELDS.notes4.visible">
            <b-form-group class="form-group fontField" label-for="notes4">
              <template v-slot:label>
                {{ $t('field.notes4') }} <span v-if="config.CUSTOMER_DATA.FIELDS.notes4.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg" id="notes4" name="notes4" type="text" v-model="$v.form.notes4.$model"
                            :state="validateState('notes4')" aria-describedby="notes4-feedback" :maxLength="config.CUSTOMER_DATA.FIELDS.notes4.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="notes4-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-show="config.CUSTOMER_DATA.FIELDS.otherInfos.visible">
          <b-col cols="12" md="12">
            <b-form-group class="form-group fontField" label-for="otherInfos">
              <template v-slot:label>
                {{ $t('field.otherInfos') }} <span v-if="config.CUSTOMER_DATA.FIELDS.otherInfos.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg" id="otherInfos" name="otherInfos" type="text" v-model="$v.form.otherInfos.$model"
                            :state="validateState('otherInfos')" aria-describedby="otherInfos-feedback" :maxLength="config.CUSTOMER_DATA.FIELDS.otherInfos.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="otherInfos-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col cols="12" md="12">
            <i18n class="text-left" path="text.acceptTermsAndPrivacy" tag="p">
              <template v-slot:terms>
                <a :href="config.COMPANY.links.privacy" target="_blank">{{ $t('link.terms') }}</a>
              </template>
              <template v-slot:privacy>
                <a :href="config.COMPANY.links.terms" target="_blank">{{ $t('link.privacy') }}</a>
              </template>
            </i18n>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col cols="12" md="12">
            <b-button type="submit" @click.stop="onClickCaptcha" v-if="!isGuestCheckout" :disabled="$v.$invalid" class="btn btn-primary btn-lg btn-block btnSubmit g-recaptcha w-100 noMargin">{{ $t('button.submit') }}</b-button>
          </b-col>
        </b-form-row>
      </b-form>
    </div>
</template>

<script>
import {sameAs, email, requiredIf} from 'vuelidate/lib/validators'
import {en, de, fr} from 'vuejs-datepicker/dist/locale'
import {BIconEyeFill, BIconEyeSlashFill} from "bootstrap-vue";
import selectPickerCountry from '../sub-components/SelectPickerCountry.vue'

export default {
    data() {
        return {
          form: {
            firstName: undefined,
            lastName: undefined,
            streetAddress: undefined,
            postcode: undefined,
            city: undefined,
            countryId: undefined,
            telephoneNumber: undefined,
            emailAddr: undefined,
            cardNumber: undefined,
            customerCode: undefined,
            notes1: undefined,
            notes2: undefined,
            notes3: undefined,
            notes4: undefined,
            otherInfos: undefined,
            password: undefined,
            passwordRepeat: undefined,
          },
            isLoading:false,
            en: en,
            de: de, 
            fr: fr,
            countriesLoaded: false,
            countries: {
              Type: Array
            },
            selectedCountry: {
                Type:Object
            },
            selectedCountryId: 0,
            responseToken: "",
            hidePassword: true,
            hidePasswordRepeat: true,
            submit: false,
        }
	},
    computed: {
        isTableMode(){
            if(window.TableMode == true)
                return true;
            else
                return false;
        },
        isGuestCheckout(){
            if(window.isGuestCheckout == true)
                return true;
            else
                return false;
        },
        config() {
            return window.config;
        },
    },
    components:{
      BIconEyeFill, BIconEyeSlashFill,
      'select-picker-country': selectPickerCountry,
    },
    validations: { // STANDARD
        form: {
            firstName: {
              required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.firstName.required),
            },
            lastName:{
              required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.lastName.required),
            },
            streetAddress:{
              required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.streetAddress.required),
            },
            postcode:{
              required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.postcode.required),
            },
            city:{
              required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.city.required),
            },
            countryId:{
              required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.countryId.required),
            },
            telephoneNumber:{
              required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.telephoneNumber.required),
            },
            emailAddr:{
              required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.emailAddr.required),
              email,
            },
            cardNumber:{
              required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.cardNumber.required),
            },
            customerCode: {
              required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.customerCode.required),
            },
            notes1:{
              required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.notes1.required),
            },
            notes2:{
              required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.notes2.required),
            },
            notes3:{
              required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.notes3.required),
            },
            notes4:{
              required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.notes4.required),
            },
            otherInfos:{
              required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.otherInfos.required),
            },
            password: {
              required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.password.required),
              valid(value) {
                const containsUppercase = /[A-Z]/.test(value);
                const containsLowercase = /[a-z]/.test(value);
                const containsNumber = /[0-9]/.test(value);
                const containsSpecial = /[#?!@$%^&*-]/.test(value);
                const hasMinLength = value.length >= this.config.CUSTOMER_DATA.FIELDS.password.minLength;
                const hasMaxLength = value.length <= this.config.CUSTOMER_DATA.FIELDS.password.maxLength;
                return (
                    containsUppercase &&
                    containsLowercase &&
                    containsNumber &&
                    containsSpecial &&
                    hasMinLength &&
                    hasMaxLength
                );
              },
            },
            passwordRepeat: {
              required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.passwordRepeat.required),
              sameAsPassword : sameAs('password')
            },
        }
    },
    methods: {
        validateState(name) {
          const {$dirty, $error} = this.$v.form[name];
          return $dirty ? !$error : null;
        },
          // eslint-disable-next-line no-undef
        onClickCaptcha(e) {
            var vueInstance = this;
            vueInstance.submit = true;
            console.log("onClickCaptcha");
            e.preventDefault();
            if(window.config.G_CAPTCHA.enabled == true)
            {
                // eslint-disable-next-line no-undef
                grecaptcha.ready(function() {
                    // eslint-disable-next-line no-undef
                    grecaptcha.execute(window.config.G_CAPTCHA.siteKey, {action: 'submit'}).then(function(token) {
                        vueInstance.responseToken = token;
                        vueInstance.onSubmit(e);                        
                    });
                });
            }
            else
            {
                vueInstance.onSubmit(e);
            }
        },
        showPassword() {
            this.hidePassword = !this.hidePassword;
        },
        showPasswordRepeat() {
          this.hidePasswordRepeat = !this.hidePasswordRepeat;
        },
        selectCountry(countryId) {
            this.form.countryId = countryId;      
        },
        goBack() {
            this.$router.back();
        },
        async onSubmit() {
          let vueInstance = this;
          vueInstance.$v.$touch();

          if (vueInstance.$v.$invalid) {
            vueInstance.makeToast('danger', vueInstance.$t('notification.fillAllRequiredFields'));
            return;
          }

          const result = await vueInstance.doPost('TCPCustomerRegistrationSubmitJ.action', {
            json: "true",
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined,
            responseToken: vueInstance.responseToken != undefined ? vueInstance.responseToken : undefined,
            gender: vueInstance.form.gender != undefined ? vueInstance.form.gender : '-',
            firstName: vueInstance.form.firstName != undefined ? vueInstance.form.firstName : '---',
            lastName: vueInstance.form.lastName != undefined ? vueInstance.form.lastName : '---',
            emailAddr: vueInstance.form.emailAddr,
            cardNumber: vueInstance.form.cardNumber != undefined ? vueInstance.form.cardNumber : undefined,
            customerCode: vueInstance.form.customerCode != undefined ? vueInstance.form.customerCode : undefined,
            streetAddress: vueInstance.form.streetAddress != undefined ? vueInstance.form.streetAddress : '---',
            postcode: vueInstance.form.postcode != undefined ? vueInstance.form.postcode : '---',
            city: vueInstance.form.city != undefined ? vueInstance.form.city : '---',
            countryId: vueInstance.form.countryId != undefined ? vueInstance.form.countryId : undefined,
            telephoneNumber: vueInstance.form.telephoneNumber != undefined ? vueInstance.form.telephoneNumber : '---',
            notes1: vueInstance.form.notes1 != undefined ? vueInstance.form.notes1 : undefined,
            notes2: vueInstance.form.notes2 != undefined ? vueInstance.form.notes2 : undefined,
            notes3: vueInstance.form.notes3 != undefined ? vueInstance.form.notes3 : undefined,
            notes4: vueInstance.form.notes4 != undefined ? vueInstance.form.notes4 : undefined,
            otherInfos: vueInstance.form.otherInfos != undefined ? vueInstance.form.otherInfos : undefined,
            password: vueInstance.form.password,
            privacy: '1',
            tc: '1',
          });

          if (result.errorCode > 0) {
            if (result.errorCode == 10) // password does confine to everything needed (length, special characters, numbers, ...)
            {
              vueInstance.makeToast('danger', vueInstance.$t('notification.passwordPolicy'));
            } else {
              vueInstance.makeToast('danger', result.errorMsg);
            }
            return;
          }
          else if (result.exception !== undefined) {
            vueInstance.makeToast('danger', result.exception.msg);
            return;
          }

          setTimeout(() => {
            vueInstance.makeToast('success', vueInstance.$t('notification.accountCreated'));
          }, 300);

          vueInstance.$navigateToLogin();
        },
    },
    async created(){
      let vueInstance = this;

      const result = await vueInstance.doGet('TCPCustomerRegistrationJ.action', {
        json: "true",
        countryChange: 1
      });

      vueInstance.countries = result.countries;
      if (result.selectedCountry != undefined) {
        vueInstance.selectedCountry = result.selectedCountry;
        vueInstance.form.countryId = result.selectedCountry.id;
        vueInstance.selectedCountryId = result.selectedCountry.id;
      } else {
        if (vueInstance.countries.length > 0)
          vueInstance.selectCountry(vueInstance.countries[0].id);
      }

      vueInstance.countriesLoaded = true;
    },

}
</script>

<style scoped>
    input[type="password"]::-ms-reveal {
      display: none;
    }
</style>