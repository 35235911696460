<template>
  <div class="meal-planner">
    <div class="header-sticky">
      <WeekDateSlider @daySelected="handleDateSelection" />
      <Toolbar
          v-if="mealPlans.length > 1"
          :toolbarItems="mealPlans"
          @item-click="selectMealPlan"
      />
      <ReservationList
          v-if="selectedMealPlan && selectedMealPlan.reservation"
          :reservation="selectedMealPlan.reservation" @reservation-canceled="handleReservationCanceled()"
      />
    </div>
    <MealPlan v-if="selectedDate && selectedMealPlan && !selectedMealPlan.reservation" :selectedMealPlan="selectedMealPlan" />
  </div>
</template>

<script>
import MealPlan from "@/components/page-components/MealPlanner/MealPlan.vue";
import WeekDateSlider from "@/components/layout-components/WeekDateSlider.vue";
import Toolbar from "@/components/layout-components/Toolbar.vue";
import { productService } from "@/services/api/productService";
import Utils from "@/utils/utils";
import ReservationList from "@/components/page-components/MealPlanner/ReservationList.vue";
import {useMealPlansStore} from "@/stores/mealPlans";

export default {
  name: "MealPlanner",
  components: {
    ReservationList,
    WeekDateSlider,
    Toolbar,
    MealPlan,
  },
  data() {
    return {
      selectedDate: null,
      selectedMealPlan: null,
      mealPlans: [],
      isLoading: false,
    };
  },
  setup() {
    const mealPlanStore = useMealPlansStore();

    return {
      mealPlanStore
    };
  },
  methods: {
    onMealPlanSelect(mealPlan) {
      this.selectedMealPlan = mealPlan;
      this.mealPlanStore.setMealPlanId(mealPlan.id);
    },
    handleReservationCanceled() {
      this.selectedMealPlan.reservation = null;
      this.makeToast("success", this.$t("notification.cancelOrderSuccessful"));
    },
    handleDateSelection(date) {
      // Only fetch meal plans if the date changes
      if (!this.selectedDate || this.selectedDate.getTime() !== date.getTime()) {
        this.selectedDate = date;
      }
    },
    async fetchMealPlans(date) {
      try {
        this.isLoading = true;
        const result = await productService.getMealPlans(
            Utils.getStartOfDate(date),
            Utils.getEndOfDate(date)
        );
        this.mealPlans = result.mealPlans || [];

        if (this.mealPlans.length > 0) {
          this.onMealPlanSelect(this.mealPlans[0]); // Auto-select the first meal plan
        } else {
          this.selectedMealPlan = null; // No meal plans available
        }
      } catch (error) {
        console.error("Failed to fetch meal plans:", error);
      } finally {
        this.isLoading = false;
      }
    },
    selectMealPlan(index) {
      // Ensure the index is valid
      if (index >= 0 && index < this.mealPlans.length) {
        this.onMealPlanSelect(this.mealPlans[index]);
      } else {
        console.error("Invalid meal plan index:", index);
      }
    },
  },
  watch: {
    selectedDate: {
      immediate: true,
      handler(newDate) {
        if (newDate) {
          this.fetchMealPlans(newDate); // Fetch meal plans for the selected date
        }
      },
    },
  },
};
</script>

<style scoped>
.meal-planner {
  text-align: center;
}
</style>