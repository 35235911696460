import { defineStore } from 'pinia';

export const usePaymentStore = defineStore('payment', {
    // State
    state: () => ({
        paymentWindow: null,
        paymentStatus: null,  // 'pending', 'success', or 'failed'
        paymentUrl: null,     // URL for the Saferpay payment page
        paymentType: null,    // Type of payment (e.g., 'credit_card', 'paypal', etc.)
    }),

    // Getters
    getters: {
        getPaymentStatus: (state) => state.paymentStatus,
        getPaymentUrl: (state) => state.paymentUrl,
        getPaymentType: (state) => state.paymentType,
    },

    // Actions
    actions: {
        // Set the payment URL and open the payment window
        async initiatePayment(externalUrl) {
            try {
                if (externalUrl) {
                    this.paymentUrl = externalUrl;
                    this.paymentWindow = window.open(externalUrl, '_self', 'width=800,height=600');

                    // Start monitoring the payment window
                    this.monitorPaymentWindow();
                }
            } catch (error) {
                console.error("Error initiating payment:", error);
                this.paymentStatus = 'failed';
            }
        },

        // Set the payment type
        setPaymentType(paymentType) {
            this.paymentType = paymentType;
        },

        // Monitor the payment window until it's closed
        monitorPaymentWindow() {
            const checkInterval = setInterval(() => {
                if (this.paymentWindow && this.paymentWindow.closed) {
                    clearInterval(checkInterval);
                    this.paymentStatus = 'success';  // Adjust based on logic as needed
                    this.clearPayment();
                }
            }, 500);
        },

        // Clear payment details
        clearPayment() {
            this.paymentWindow = null;
            this.paymentStatus = null;
            this.paymentUrl = null;
            this.paymentType = null;
        },
    },

    persist: {
        enabled: true,
        strategies: [
            {
                key: 'payment',
                storage: localStorage
            },
        ],
    },
});