export const Currencies = Object.freeze({
    CHF: 'CHF',
    EUR: 'EUR',
});

export const OrderTypes = Object.freeze({
    TAKEAWAY: 'takeaway',
    DELIVERY: 'delivery',
    DINE_IN: 'dinein',
});

export const TopupTypes = Object.freeze({
    TOPUP: 'topup',
    TOPUP_CHILD: 'topup_child',
});

export const UserTypes = Object.freeze({
    DEFAULT: '0',
    CHILD: '1',
    PARENT: '2',
});