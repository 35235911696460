import { defineStore } from 'pinia';

export const useMealPlansStore = defineStore('mealPlans', {
    // State
    state: () => ({
        mealPlans: [],                   // List of meal plans
        selectedMealPlanId: null,        // Currently selected meal plan ID
        orders: [],                      // List of orders (each has reservationDate, mealPlanId, and products)
    }),

    // Getters
    getters: {
        allMealPlans: (state) => state.mealPlans,
        selectedMealPlanId: (state) => state.selectedMealPlanId,
        selectedMealPlan: (state) =>
            state.mealPlans.find((plan) => plan.id === state.selectedMealPlanId) || null,

        ordersForMealPlanAndDate: (state) => (mealPlanId, reservationDate) =>
            state.orders.find(order => order.mealPlanId === mealPlanId && order.reservationDate === reservationDate) || null,

        productsForMealPlanAndDate: (state) => (mealPlanId, reservationDate) => {
            const order = state.orders.find(order => order.mealPlanId === mealPlanId && order.reservationDate === reservationDate);
            return order ? order.products : [];
        },
    },

    // Actions
    actions: {
        async fetchMealPlans() {
            try {
                // Fetching meal plans from an API or mock data
                const mealPlans = [
                    { id: 1, name: 'Vegan Plan', meals: 5 },
                    { id: 2, name: 'Keto Plan', meals: 7 },
                    { id: 3, name: 'Paleo Plan', meals: 6 },
                ];
                this.mealPlans = mealPlans;
            } catch (error) {
                console.error("Failed to fetch meal plans:", error);
            }
        },

        setMealPlanId(mealPlanId) {
            this.selectedMealPlanId = mealPlanId;
        },

        addProductToOrder({ mealPlanId, reservationDate, product }) {
            const order = this.orders.find(order => order.mealPlanId === mealPlanId && order.reservationDate === reservationDate);
            if (order) {
                const existingProduct = order.products.find(p => p.id === product.id);
                if (existingProduct) {
                    this.updateProductQuantity({ order, productId: product.id, quantity: product.quantity });
                } else {
                    this.addProductToExistingOrder({ order, product });
                }
            } else {
                const newOrder = {
                    mealPlanId,
                    reservationDate,
                    products: [{ ...product }]
                };
                this.orders.push(newOrder);
            }
        },

        removeProductFromOrder({ mealPlanId, reservationDate, productId }) {
            const order = this.orders.find(order => order.mealPlanId === mealPlanId && order.reservationDate === reservationDate);
            if (order) {
                this.orders = this.orders.map(o =>
                    o === order
                        ? { ...o, products: o.products.filter(product => product.id !== productId) }
                        : o
                ).filter(o => o.products.length > 0);
            }
        },

        addProductToExistingOrder({ order, product }) {
            order.products.push(product);
        },

        updateProductQuantity({ order, productId, quantity }) {
            const product = order.products.find(p => p.id === productId);
            if (product) {
                product.quantity = quantity;
            }
        },
    },
});