<template>
    <div class="shopCards startPageBackgroundImage">

        <!-- Modal for shop details -->
        <b-modal v-if="selectedShop" id="shop-details-modal" ref="shopDetailsModal" hide-footer :title="selectedShop.name">
          <ShopDetails :shop="selectedShop" />
          <order-options-page></order-options-page>
        </b-modal>
        
        <loading :active.sync="isLoading" 
            :can-cancel="true"        
            :is-full-page="true"
            loader='bars'></loading>

        <!-- wrapper to prevent showing an empty shop-card when the element is still loading -->
        <div v-if="showChoice" class="shopCards">
            <h1 class="shopPageTitle">
                    {{ $t('subTitle.chooseShop') }}
            </h1>
            <shop-carousel v-if="showCarousel" :shops="shops" :callbackSelect="selectShop"> </shop-carousel>
            <div class="col-12 shopCardWrapper" style="align-items: center;justify-content: center;">
                <shop-card v-for="shop in shops" v-bind:key="shop.id" :id="shop.id" :addresses="shop.addresses" :image="shop.image" :name="shop.name" :url="shop.custom10" :code="shop.custom2" :callback="selectShop"></shop-card>
            </div>
        </div>
    </div>
</template>

<script>
import ShopCard from '../main-components/ShopCard.vue'
import VueLoading from 'vue-loading-overlay'
import Carousel from '../sub-components/ShopCarousel.vue'
import OrderOptionsPage from '../page-components/OrderOptionsPage.vue'
import $ from 'jquery'
import Utils from "@/utils/utils";
import {UserTypes} from "@/constants/constants";
import ShopDetails from "@/components/sub-components/ShopDetails.vue";
import {shopService} from "@/services/api/shopService";

export default {
    data() {
        return {
            isLoading: false,
            shops: {
                Type: Array
            },
            visible : true,
            showChoice: false,
            selectedShop: {
                Type: Object
            },
            shopIdPreselected: 0,
            jwtString: "",
            showCarousel: true,
            shopShopModalAddress: false,
            showShopExtraDetails: false,
            deltaY: 0
        }
    },
    computed:{
        config() {
            return window.config;
        }
    },
    created() {
      this.loadShops();
    },
    beforeMount(){
        if((this.jwtString != "" && this.jwtString != undefined && this.jwtString != null) || (this.code != "" && this.code != undefined && this.code != null))
        {
            this.isLoading = true;
        }

        if(window.preselectedShopCode !== undefined)
        {
            this.shopIdPreselected = window.preselectedShopCode;
        }
        else
        {
            this.shopIdPreselected = this.shopId;
            window.preselectedShopCode = this.shopId;
        }   
        
        if(this.jwt != undefined){
            window.jwt = this.jwt;
        }
        if (this.code != undefined) {
            window.ssoCode = this.code;
        }
    },
    async mounted() {
        this.showChoice = false;
        var config = window.config;

        if((this.jwtString != "" && this.jwtString != undefined && this.jwtString != null) || (this.code != "" && this.code != undefined && this.code != null))
        {
            this.isLoading = true;
        }

        if(config.TABLE_CHECKOUT.enabled)
        {
            if (window.shopId != undefined && window.immediate)
            {
              let path = "/QRLanding?s=" + window.shopId + "&i=" + window.immediate;
              await this.$router.push({path: path});
            }
            else if (window.shopId != undefined && window.tableId != undefined)
            {
                if (this.jwt != undefined || this.code != undefined)
                {
                    let path = "/QRLanding?s=" + window.shopId + "&t=" + window.tableId; 
                    if (this.jwt != undefined) {
                    path = path + "&id_token=" + this.jwt; 
                    } else if(this.code != undefined){
                    path = path + "&code=" + this.code; 
                    }
                    window.jwt = undefined;
                    window.ssoCode = undefined;
                    await this.$router.push({path: path});
                }
                else
                {
                    //nur mit shop und tableid senden
                    let path = "/QRLanding?s=" + window.shopId + "&t=" + window.tableId;
                    await this.$router.push({path: path});
                }
            }
            else
            {
                    let path = "/QRLanding?h=true"; 
                    await this.$router.push({path: path});
            }

            if(window.tableId == undefined || window.tableId == null){
                window.location.href = config.TABLE_CHECKOUT.homepage;
            }else{
                await this.$router.push({path: "/Menu"});
            }
            return; 
        }

        await this.checkJWT();

        this.shopIdPreselected = this.shopId;
        this.isLoading = true;
        setTimeout(async () => {
          if (window.screen.width > 700 && config.SHOW_GLOBAL_HEADER_BANNER_IMAGE == true) {
            $(".header").addClass("header-big");
            $(".content").addClass("header-big-content-margin");
            $("#content-wrapper").bind("wheel", function (event) {
              this.handleWheelContent(event);
            });
          } else {
            $(".content").addClass("header-small-content-margin");
          }

          var instance = this;

          instance.visible = true;
          window.promotionBarcode = "";

          const response = await shopService.getShops(true);
          this.isLoading = false;

          instance.isLoading = false;
          instance.shops = response.shops;

          if (response.emptyBasket) {
            window.shoppingCart = response.basketTile.basketItems;
            instance.updateShoppingCart();
          }

          if (Utils.isEmpty(instance.shops)) {
            instance.makeToast('danger', this.$t('notification.noShopsAvailable'));
            return;
          }

          if (instance.shops.length === 1 && config.FLAG_AUTOSELECTSHOP) {
            instance.selectShop(instance.shops[0].id);
          }

          instance.visible = false;

          if (this.shopIdPreselected > 0) {
            console.log("ShopCode was passed! auto-selecting.");
            await this.selectShopCode(this.shopIdPreselected);
          }

          instance.showChoice = true;

          instance.visible = false;
          window.shopObj = null;
          window.timeString = null;
        }, 5);
    },
    props: {
        callback: {
            Type: Function
        },
        jwt: {
            Type:String,
            Default: "",
        },
        shopId:{
            Type: Number,
            Default: 0
        },
        code: {
            Type:String,
            Default: "",
        },
        validationType: {
          Type:String,
          Default: "",
        },
    },
    methods: {
      showShopDetails(shop) {
        this.selectedShop = shop;
        this.$nextTick(() => {
          if (this.$refs.shopDetailsModal) {
            this.$refs.shopDetailsModal.show();  // Open the modal
          } else {
            console.error('Modal reference is not defined.');
          }
        });
      },
        async getToken() {
          const result = await this.doGet('TCPLoginJ.action', {
            json: "true"
          });

          if (result.xsrfToken != undefined) {
            window.xtoken = atob(result.xsrfToken);
          }
        },  
        async checkJWT() {
          var config = window.config;
          this.showCarousel = config.SHOW_SHOP_CAROUSEL;
          this.shopShopModalAddress = config.SHOW_SHOP_DETAILS_ADDRESS;
          this.showShopExtraDetails = config.SHOW_SHOP_EXTRA_DETAILS;
          window.shopSelected = false;

          this.isLoading = true;
          this.jwtString = this.jwt;

          if ((this.jwtString != "" && this.jwtString != undefined && this.jwtString != null) || (this.code != "" && this.code != undefined && this.code != null)) {
            this.getToken();

            const result = await this.doPost('TCPLoginWithJWTJ.action', {
              jwt: !Utils.isEmpty(this.jwtString) ? this.jwtString : undefined,
              code: !Utils.isEmpty(this.code) ? this.code : undefined,
              shopCode: !Utils.isEmpty(this.shopId) ? this.shopId : undefined,
              validationType: !Utils.isEmpty(this.validationType) ? this.validationType : undefined,
              returnCustomerData: "true"
            });

            if (result.exception !== undefined || result.errorCode < 0) {
              setTimeout(() => {
                this.makeToast('danger', this.$t('notification.SSOLoginError'));
              }, 300);
              this.$navigateToErrorSSO();
              return;
            }

            if (result.customer == undefined) {
              console.log("JWT Login unsuccesful!");
              if (result.requiresLogin == false) {
                console.log("A user is already logged in, JWT ignored!");
                return;
              }
              if (config.FORCE_LOGIN_BEFORE_USE) {
                console.log("Routing to LOGIN");
                this.$navigateToLogin();
                return;
              }
            }

            if (!Utils.isEmpty(this.code)) {
              window.preselectedShopCode = this.code;
            }

            this.isLoading = false;
            this.showChoice = true;
            this.customer = result.customer;
            window.customerLoggedIn = true;

            if (this.shopIdPreselected > 0) {
              console.log("ShopCode was passed! auto-selecting.");
              await this.selectShopCode(this.shopIdPreselected);
            }

            if (UserTypes.PARENT === result.customer.custom3) {
              console.log("Routing to StudentAccounts");
              window.isParent = true;
              this.$navigateToStudentAccounts(true);
            }
            else if (!Utils.isEmpty(this.shopId)) {
              console.log("Routing to Menu");
              this.$navigateToMenu();
            }
            else if (config.IS_PURE_ACCOUNTMANAGER) {
              console.log("Routing to Account");
              this.$navigateToAccount(true);
            }
          } else {
            this.showChoice = true;

            this.isLoading = false;

            if (config.IS_PURE_ACCOUNTMANAGER) {
              console.log("Routing to Account");
              await this.$router.push({path: "/Account"});
            }

            if (!config.FORCE_LOGIN_BEFORE_USE) {
              return;
            }
            console.log("forceLogin");
            await this.forceLogin();
          }
        },
        async loadShops() {
          this.isLoading = true;
          const response = await shopService.getShops(true);
          this.isLoading = false;

          if (response.shops.empty) {
            this.makeToast('danger', this.$t('notification.noShopsAvailable'));
            return;
          }
          if (response.emptyBasket) {
            window.shoppingCart = response.basketTile.basketItems;
            window.shoppingTile = response.basketTile;
            // this.updateShoppingCart();
          }

          this.shops = response.shops;

          if (this.shops.length === 1 && this.config.FLAG_AUTOSELECTSHOP) {
            this.selectShop(this.shops[0].id);
          }
        },
        async forceLogin() {
          const result = await this.doGet('TCPAfterLoginJ.action', {
            json: "true",
          });

          this.customer = result.customer;

          if (result.customer != undefined) {
            window.isGuestCheckout = result.customer.type != undefined && result.customer.type == 2 ? true : false;
          } else {
            // JP 20221005
            if (window.config.FORCE_LOGIN_BEFORE_USE == true) {
              console.log("Routing to LOGIN");
              await this.$router.push({path: "/Login"});
              return;
            }
          }
        },
        updateShoppingCart: async function () {
          const result = await this.doPost('TCPConfirmOrderJ.action', {
            calculateOrder: window.customerLoggedIn ? true : false,
            orderType: window.customerLoggedIn ? window.orderType : undefined,
            promotionBarcode: window.promoBarcode != undefined ? window.promoBarcode : undefined
          });

          window.shoppingCart = result.basketItems;
          window.subsidy = result.basketTile.paymentsList != undefined ? this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";
          window.xtoken = result.xsrfToken != undefined ? atob(result.xsrfToken) : undefined;
        },
        formatCurrency(value){
            var formatter = new Intl.NumberFormat(this.config.PRICE_NUMBER_FORMAT, {style: 'currency', currency: window.config.SUBSIDY_CURRENCY});
            return formatter.format(value);
        },
        maximizeHeader() {
            var config = window.config;
            if(config.SHOW_GLOBAL_HEADER_BANNER_IMAGE)
            {
                $(".header").addClass("header-big");
                $(".header").addClass("animTransition");
                $(".content").addClass("header-big-content-margin");
                $(".content").removeClass("header-small-content-margin");
            }
           
        },
        minimizeHeader() {
            var config = window.config;
            if(config.SHOW_GLOBAL_HEADER_BANNER_IMAGE)
            {
                $(".header").removeClass("header-big");
                $(".header").addClass("animTransition");
                $(".content").removeClass("header-big-content-margin");
                $(".content").addClass("header-small-content-margin");
            }       
        },
        handleWheelContent(event) {
            if (event.originalEvent !== undefined && Utils.isGreater(event.originalEvent.deltaY, 0))
            {
              this.minimizeHeader();
            }
        },
        async selectShop(shopId, shopCode) {
          let config = window.config;

          this.isLoading = true;
          await shopService.selectShop(shopId);
          this.isLoading = false;

          window.shopSelected = true;
          window.shop = shopId;
          var shopcopyid = shopId;
          this.selectedShop = this.shops.filter(shop => shop.id.toString() === shopcopyid.toString())[0];
          window.ageVerification = config.AGE_VERIFICATION_SHOPCODES.includes(shopCode) ? true : false;
          window.shopObj = this.selectedShop;

          this.showShopDetails(this.selectedShop)
        },
        async selectShopCode(shopCode) {
          var config = window.config;
          this.isLoading = true;
          var shopcopycode = shopCode;
          var shop = this.shops.filter(shop => shop.custom2.toString() === shopcopycode)[0];
          if (shop == undefined) {
            console.log("ShopCode " + shopCode + " not found! Shop could not be auto-selected!")
            return;
          }

          await this.doGet('TCPSelectShopJ.action', {
            id: shop.id,
            json: "true"
          });

          this.isLoading = false;
          console.log("Select Shop success: " + shopCode);
          this.selectedShop = this.shops.filter(shop => shop.custom2.toString() === shopcopycode)[0];
          window.shopSelected = true;
          window.shop = this.selectedShop.id;
          window.shopObj = this.selectedShop;
          this.$refs.shopModal.openModal()
          window.ageVerification = config.AGE_VERIFICATION_SHOPCODES.includes(shopCode) ? true : false;
        }
    },
    components: {
      ShopDetails,
        'shop-card' : ShopCard,
        'shop-carousel' : Carousel,
        'loading': VueLoading,
        'order-options-page':OrderOptionsPage
    },
    watch: {
      '$i18n.locale': function(newVal, oldVal) {
        if(newVal != oldVal)
        {
          this.loadShops();
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    beforeRouteEnter (to, from, next) {
         // eslint-disable-next-line no-unused-vars
         next(vm => {          
            var myDiv = document.getElementById('contentSection');
            $("#contentSection").scrollTop(0);
            $("#app").scrollTop(0);
            
            if(myDiv != undefined)
              if(myDiv != null)
                myDiv.scrollTop = 0;                            
        })        
    },
}
</script>

<style scoped>
    .shopCardWrapper {
        display:flow-root;
        padding: 2% 10%;
    } 
@media screen and (max-width:770px) {
    .shopCardWrapper {
        display:flow-root;
    }
}

</style>