import { defineStore } from 'pinia';

export const useOrderStore = defineStore('orderType', {
    // State
    state: () => ({
        orderType: null, // Initial value for orderType
    }),

    // Getters
    getters: {
        getOrderType: (state) => state.orderType,
    },

    // Actions
    actions: {
        // Action to update the order type
        updateOrderType(orderType) {
            this.orderType = orderType;
        },
    },
});