<template>
  <div>
    <b-spinner v-if="loading" label="Authenticating..."></b-spinner>
  </div>
</template>

<script>
import Utils from "@/utils/utils";
import {UserTypes} from "@/constants/constants";
import {useLocaleStore} from "@/stores/locale";

export default {
  data() {
    return {
      loading: true,
    };
  },
  computed:{
    config() {
      return window.config;
    }
  },
  props: {
    callback: {
      Type: Function
    },
    jwt: {
      Type:String,
      Default: "",
    },
    shopId:{
      Type: Number,
      Default: 0
    },
    code: {
      Type:String,
      Default: "",
    },
    validationType: {
      Type:String,
      Default: "",
    },
    immediate: {
      Type: Boolean
    },
  },
  setup() {
    const localeStore = useLocaleStore();

    return {
      localeStore
    };
  },
  methods: {
    async changeLanguage(newLocale) {
      await this.localeStore.setLocale(newLocale);
    },
    async handleSSO() {
      const token = this.jwt;
      const code = this.code;
      const shopCode = this.shopId;
      const validationType = this.validationType;
      console.log("login over LoginSSOPage.vue");
      if (token) {
        try {

          const result = await this.doPost('TCPLoginWithJWTJ.action', {
            jwt: token,
            code: !Utils.isEmpty(code) ? code : undefined,
            shopCode: !Utils.isEmpty(shopCode) ? shopCode : undefined,
            validationType: !Utils.isEmpty(validationType) ? validationType : undefined,
            returnCustomerData: "true"
          });

          if (result.exception !== undefined || result.errorCode < 0) {
            this.$navigateToErrorSSO();
            return;
          }

          this.customer = result.customer;
          window.customerLoggedIn = true;

          if (this.config.USE_EXTERNAL_LANGUAGE) {
            const newLocale = !Utils.isEmpty(result.customer.locale) ? result.customer.locale : this.config.LANGUAGE_LIST[0].value;
            await this.changeLanguage(newLocale.replace('-', '_'));
          }

          if (UserTypes.PARENT === result.customer.custom3) {
            window.isParent = true;
            this.$navigateToStudentAccounts(true);
          } else if (!Utils.isEmpty(shopCode)) {
            this.vueInstance.$navigateToMenu();
          }
          //Paremter auslesen "i" == true 2. ImmediateOrder aufrufen 3. This navigate zum Menu
          //else if(this.immediate)
          //{
          //  //Schritt 1 Parameter auselsen
          //  //Schritt 2 ImmediateOrder aufrufen //this.shopId mitgeben
//
          //  //Bei Fehler
          //  if (result.exception !== undefined || result.errorCode < 0) {
          //    this.$navigateToErrorSSO();
          //  }
          //  else
          //  {
          //    //
          //    this.vueInstance.$navigateToMenu();
          //  }
          //}
        } catch (error) {
          this.$navigateToErrorSSO();
        }
      } else {
        this.$navigateToErrorSSO();
      }
    },
  },
  created() {
    this.handleSSO();
  },
};
</script>
