import { defineStore } from 'pinia';
import {removeTimerBase} from "@/store/SessionTimer";

export const useAuthStore = defineStore('auth', {
    state: () => ({
        isAuthenticated: false,
    }),

    getters: {
        isLoggedIn: (state) => state.isAuthenticated,
    },

    actions: {
        setAuthenticated(isAuthenticated) {
            this.isAuthenticated = isAuthenticated;
            if (!isAuthenticated) {
                removeTimerBase();
            }
        },
    },

    persist: {
        enabled: true,
        strategies: [
            {
                key: 'auth',
                storage: localStorage
            },
        ],
    },
});
