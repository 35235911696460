<template>
  <div class="creditCardPaymentWrapper" id="credit-card-payment">

    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <div class="innerScroll">
      <div id="credit-card-payment-bottom" style="display:flex; height:1px; width:100%;color:white; background:rgba(0,0,0,0)"></div>

      <div class="col-12" id="credit-cards-wrapper" v-if="vaultedCards != null && vaultedCards.length > 0" style="padding:0;padding-top:100px;">

        <div class="creditCardCard" v-for="card in vaultedCards" :key="card.id" :value="card.id" @click="selectCreditCard(card.id)">
          <img class="creditCardCardBrandImage" v-if="card.brand == 'MasterCard'" src="@/assets/images/mastercard.png" />
          <img class="creditCardCardBrandImage" v-if="card.brand == 'VISA'" src="@/assets/images/visa.png" />
          <img class="creditCardCardBrandImage" v-if="card.brand == 'American Express'" src="@/assets/images/amex.png" />
          <img class="creditCardCardBrandImage" v-if="card.brand != 'VISA' && card.brand != 'MasterCard' && card.brand != 'American Express'" src="@/assets/images/creditcard.png" />

          <br/>
          <span v-html="card.maskedNumber" class="cardNumber"></span>
          <br/>
          <span v-html="card.expMonth + '/' + card.expYear" class="cardInfo"></span>
          <br/>
          <b-button class="btn btn-primary" style="margin:0px; margin-left:15px; padding:3px; font-size:15px;" @click="deleteCreditCard(card.id)">{{$t('button.deleteCreditCard')}}</b-button>
        </div>

        <b-button class="btn btn-primary" style="min-width:20px;" @click="storeCardPrepareVaulted">
          {{$t("addNewCreditCard")}}
        </b-button>
      </div>

      <div class="vaultedCardsNotation" id="vaulted-cards-notation" v-if="transactionInterface && showVaultedCardsNotation && !isTableMode">
        {{$t("vaultedCardsNotation")}}
      </div>
    </div>
  </div>
</template>

<script>
import VueLoading from 'vue-loading-overlay'
import {paymentService} from "@/services/saferpay/paymentService";

export default {
  data() {
    return {
      externalUrl: "",
      isLoading: true,
      vaultedCards: null,
      noVaultedCards: true,
      creditCardId: "",
      showVaultedCardsNotation: false,
      transactionInterface: false,
    };
  },
  props: {
    subCode: {
      type: String
    }
  },
  computed: {
    config() {
      return window.config;
    },
    isTableMode() {
      return window.TableMode || false;
    }
  },
  methods: {
    async deleteCreditCard(cardId) {
      const result = await this.doPost('SaferpayDeleteCardSubmitJ.action', {
        json: "true",
        cardId: cardId
      });

      if (result.errorCode < 0) {
        this.makeToast('danger', result.errorMsg);
        return;
      }

      await this.loadSaferpay();
    },
    selectCreditCard(id) {
      this.isLoading = true;
      this.creditCardId = id;
      this.selectCard(this.creditCardId);
    },
    async storeCardPrepareVaulted() {
      const result = await this.doPost('SaferpayJ.action', {
        json: "true",
        storeCard: "true"
      });

      if (result.errorCode < 0) {
        this.makeToast('danger', result.errorMsg);
        return;
      }

      this.externalUrl = result.redirectURL;
      this.isLoading = true;

      this.showVaultedCardsNotation = true;

      this.openExternalUrl();
    },
    async selectCard(cardId) {
      const result = await this.doPost('SaferpayJ.action', {
        json: "true",
        cardId: cardId
      });

      if (result.errorCode < 0) {
        this.makeToast('danger', result.errorMsg);
        return;
      }

      this.externalUrl = result.redirectURL;
      this.openExternalUrl();
    },
    async loadSaferpay() {
      const result = await paymentService.initPayment()

      this.transactionInterface = this.subCode === "T";

      if (result.errorCode < 0) {
        this.makeToast('danger', result.errorMsg);
        return;
      }

      if (result.page === "catalog.saferpayvault.page") {
        this.vaultedCards = result.vaultedCards || [];
        if (this.vaultedCards.length === 0) {
          this.storeCardPrepareVaulted();
          return;
        }
      } else {
        this.vaultedCards = result.vaultedCards;
        this.storeCardPrepareVaulted();
        return;
      }

      this.externalUrl = result.redirectURL;
      this.openExternalUrl();

      this.isLoading = false;
    },
    openExternalUrl() {
      if (this.externalUrl) {
        window.open(this.externalUrl, '_self');
        // this.initiatePayment(this.externalUrl);
      }
    }
  },
  components: {
    'loading': VueLoading
  },
  created() {
    let scrollDiv = document.getElementById('contentSection');
    if (scrollDiv) {
      scrollDiv.style.overflowY = 'hidden';
    }
  },
  mounted() {
    if (this.subCode === "T") {
      this.transactionInterface = true;
    }
    this.loadSaferpay();
  },
  beforeRouteLeave(to, from, next) {
    var scrollDiv = document.getElementById('contentSection');
    if (scrollDiv) {
      scrollDiv.style.overflowY = 'auto';
    }

    clearInterval(this.intervalId);
    next();
  }
};
</script>

<style>
/* Keep the styles unchanged */
</style>