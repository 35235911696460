<template>
  <div>
    <img @click="navigateToHome" id="LogoImage" :src="logoPath" :alt="$t('ariaLabel.logo')"/>
    <img @click="navigateToHome" id="LogoImageSmall" :src="smallLogoPath" :alt="$t('ariaLabel.logo')"/>
  </div>
</template>

<script>

export default {
  name: "logo-image",
  computed: {
    logoPath() {
      return process.env.BASE_URL + 'settings/resources/logo.png';
    },
    smallLogoPath() {
      return process.env.BASE_URL + 'settings/resources/logo_small.png';
    },
    config() {
      return window.config;
    }
  },
  methods: {
    navigateToHome() {
/*
      if (this.config.RELOAD_START_PAGE.enabled) {
        if (!this.$route.path == this.config.DEFAULT_START_PAGE.path ) {
          this.$router.push({ path: this.config.DEFAULT_START_PAGE.path });
        }
      } else {
        this.$navigateToDashboard();
      }
*/

      /*if (window.config.TABLE_CHECKOUT.enabled)
      {
        window.location = window.config.TABLE_CHECKOUT.homepage;
        return;
      }

      if (window.config.IS_PURE_ACCOUNTMANAGER)
      {
        return;
      }
      var vueInstance = this;
      if (!(vueInstance.$route.path == '/Shop'))
      {
        if (window.shoppingCart != undefined && window.shoppingCart != null && window.shoppingCart.length > 0)
        {
          vueInstance.$confirm(
              vueInstance.$t('notification.resetBasketInfo'),
              vueInstance.$t('subTitle.resetBasketInfo'),
              'info',
              {
                confirmButtonText: vueInstance.$t('button.confirm'),
                cancelButtonText: vueInstance.$t('button.cancel')
              }).then(() => {
            if (!(vueInstance.$route.path == '/Shop') && !window.TableMode)
            {
              vueInstance.$router.push({path: '/Shop'})
            }
          });
        }
        else
        {
          vueInstance.$router.push({path: '/Shop'});
        }

      }*/
    },
  }
}
</script>

<style scoped>
#LogoImage {
  display: initial;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
#LogoImageSmall {
  display: none;
  height: 100%;
  width: auto;
}
@media only screen and (max-width: 767px) {
  #LogoImage {
    display: none;
  }
  #LogoImageSmall {
    display: initial;
  }
}
</style>